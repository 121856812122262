import { useProfile } from "../../contexts/ProfileContext";
import Token from "../Token";
import LevelBar from "../LevelBar";
import CombinedToken from "../CombinedToken";

export default function ProfileGallery() {
  const { assets, profile } = useProfile();
  // Sort tokens by points accumulated
    const erc1155Map = assets?.filter(a => a.resourceStandard === 'ERC1155')
        .reduce((map, asset) => {
            const key = `${asset.contractAddress}-${asset.tokenId}`;
            if (!map[key]) {
                map[key] = [];
            }
            map[key].push(asset);
            return map;
        }, {});

    const erc1155Combined = [];
    for (let key in erc1155Map) {
        if (erc1155Map.hasOwnProperty(key)) {
            // aggregate all of the other token's points onto the first one.
            const assetsWithKey = erc1155Map[key].sort((a1, a2) => a2.points - a1.points);
            // deep clone to prevent shallow references
            const aggregatedAsset = JSON.parse(JSON.stringify(assetsWithKey[0]));
            aggregatedAsset.points = assetsWithKey.map(asset => asset.points)
                .reduce((sum, point) => point + sum, 0);
            aggregatedAsset.numHeld = assetsWithKey.length;
            erc1155Combined.push(aggregatedAsset);
        }
    }
    // remove the erc1155s
  const tokens = assets?.filter(a => a.resourceStandard !== 'ERC1155')
      // add back the erc1155s that are combined into one asset per contract address token id pair
      .concat(erc1155Combined)
      .sort((a,b) => b.points - a.points)
      .map((asset, index) => {
          if (asset.resourceStandard === 'ERC1155') {
              return <CombinedToken key={index} asset={asset} numHeld={asset.numHeld}/>;
          } else {
            return <Token key={index} asset={asset} />;
          }
      });

  const ProfileGalleryContent = () => {
    if (!profile) return null;

    if (!profile.email) return (
      <div className="bg-white border border-black mb-0.5 pt-1.5 pb-[30px] px-2">
        <p>Add an email to view your Gallery.</p>
      </div>
    );

    if (!tokens || tokens.length === 0) return (
      <div className="bg-white border border-black mb-0.5 pt-1.5 pb-8 px-2">
        <p>You don&rsquo;t have any assets eligible for CPG Rewards&trade;. Ways you can get started:</p>
        <ul className="list-disc list-inside marker:content-['→'] ml-1">
          <li><span className="pl-1">Buy a <a href="https://opensea.io/collection/crypto-packaged-goods" target="_blank" className="active:border-black active:bg-black active:text-cpg-paper-white border-b border-black duration-150 ease-linear hover:bg-cpg-blue-500 hover:border-cpg-blue-500 hover:text-cpg-paper-white pt-1">CPG Genesis NFT</a></span></li>
          <li><span className="pl-1">Buy a <a href="https://opensea.io/collection/cpg-pop" target="_blank" className="active:border-black active:bg-black active:text-cpg-paper-white border-b border-black duration-150 ease-linear hover:bg-cpg-blue-500 hover:border-cpg-blue-500 hover:text-cpg-paper-white pt-1">CPG Pop NFT</a></span></li>
          <li><span className="pl-1">Collect POAPs by engaging with the <a href="https://t.me/+UM77pmRQDX40Mzkx" target="_blank" className="active:border-black active:bg-black active:text-cpg-paper-white border-b border-black duration-150 ease-linear hover:bg-cpg-blue-500 hover:border-cpg-blue-500 hover:text-cpg-paper-white pt-1">free community chat on Telegram</a></span></li>
        </ul>
      </div>
    );

    return (
      <>
        <LevelBar/>
        <div className="grid grid-flow-row md:grid-cols-3 2xl:grid-cols-4 grid-cols-2 auto-rows-auto gap-0.5">
            {tokens}
        </div>
      </>
    )
  };

  return (
    <div id={profile && profile.profileId}>
      <div className="mb-0.5">
        <h1 className="bg-black text-white pt-1.5 pb-1 pl-2">Gallery</h1>
      </div>
      <ProfileGalleryContent/>
    </div>
  );
}
