import React, {useEffect, useState} from "react";
import {ethers } from 'ethers';
import {toast} from "react-hot-toast";
import {API} from "aws-amplify";
import {CPG_COMMUNITY_ID} from "../../utils/constants";
import useSession from "../../hooks/use-session";
import PropTypes from 'prop-types';
import useAlchemyClient from "../../hooks/use-alchemy-client";
import {getOpenSeaUrlForCollection} from "../../utils/helpers";
import PointOverridesComponent from "../PointOverrides";

const ENV = process.env.REACT_APP_AMPLIFY_ENVIRONMENT;

export default function CollectionAddForm({ onCancel, onAdd }) {
  const [pointsType, setPointsType] = useState(null);
  const [points, setPoints] = useState(0);
  const [displayName, setDisplayName] = useState('');
  const [enabled, setEnabled] = useState(true);
  const [tokenStandard, setTokenStandard] = useState(null);
  const [network, setNetwork] = useState(null);
  const [nerfingEnabled, setNerfingEnabled] = useState(false);
  const [nerfingConfig, setNerfingConfig] = useState(null);
  const [overridePointsForTokenIds, setOverridePointsForTokenIds] = useState(false);
  const [pointsTokenIdOverrideConfig, setPointsTokenIdOverrideConfig] = useState(null);
  const [contractAddress, setContractAddress] = useState('');
  const [contractMetadata, setContractMetadata] = useState(null);
  const [isSaving, setIsSaving] = useState(false);
  const { sessionStore } = useSession();
  const { alchemyEthereumClient, alchemyPolygonClient } = useAlchemyClient();

  const { message, signature } = sessionStore.getUserSessionInfo();


  console.log(pointsType, points, displayName, enabled,
      tokenStandard, network, nerfingEnabled, nerfingConfig, overridePointsForTokenIds,
      pointsTokenIdOverrideConfig, contractAddress);


  useEffect(() => {
    if(!ethers.utils.isAddress(contractAddress)) {
      setTokenStandard(null);
      setContractMetadata(null);
      return;
    }

    // Get contract metadata from API
    const getContractMetadata = async () => {
      setTokenStandard(null);
      setContractMetadata(null);
      const alchemyClient = network === 'ETHEREUM' ? alchemyEthereumClient : alchemyPolygonClient;
      try {
        const getMetadataPromise = alchemyClient.nft.getContractMetadata(contractAddress);
        const loadingToastPromise = toast.promise(getMetadataPromise, {
          success: 'Valid NFT address',
          loading: 'Verifying the collection...',
          error: 'Error while verifying your collection.'
        });
        const metadata = await getMetadataPromise;
        console.log(metadata);
        if (metadata.tokenType === 'UNKNOWN') {
          const toastId = (await loadingToastPromise).id;
          toast.dismiss(toastId);
          toast.error('Invalid NFT contract address');
        } else {
          setTokenStandard(metadata.tokenType);
          setContractMetadata(metadata);
        }
      } catch (error) {
        console.error('Failed to get contract metadata: ', error);
        toast.error('Failed to verify contract address');
      }

    };
    getContractMetadata();

  }, [contractAddress]);

  useEffect(() => {
    setContractAddress('');
    setContractMetadata(null);
    setTokenStandard(null);
  }, [network]);

  const editingEnabled = true;

  // Save form
  const saveForm = async () => {
    if (points < 0 || isNaN(points)) {
      toast.error('Invalid Points. Must be greater or equal to 0.');
      return;
    }
    if (displayName.length <= 0) {
      toast.error('Please provide a Collection Name.');
      return;
    }
    if (pointsType !== 'ACCUMULATE_MONTHLY' && pointsType !== 'FIXED') {
      toast.error('Invalid points type');
      return;
    }
    if (!pointsType || !tokenStandard || !network) {
      toast.error('Please fill out all required values.');
      return;
    }
    if (!ethers.utils.isAddress(contractAddress)) {
      toast.error('Invalid Contract Address');
      return;
    }


    setIsSaving(true);

    try {
      const addCollectionResp = await  API.post("community", `/community/${CPG_COMMUNITY_ID}/collection`, {
        response: true,
        body: {
          pointsType, points, displayName: displayName.trim(), enabled,
          tokenStandard, network, nerfingEnabled, nerfingConfig, overridePointsForTokenIds,
          pointsTokenIdOverrideConfig, contractAddress
        },
        headers: {
          "X-Cpg-Auth": [`m=${message}`, `s=${signature}`],
        },
      });
      console.log(addCollectionResp);
      toast.success(`${displayName} added successfully.`);
      return onAdd(addCollectionResp.data.collection);
    } catch (error) {
      console.error('Failed to save new collection due to', error, error?.response?.data?.error);
      toast.error(error?.response?.data?.error);
    } finally {
      setIsSaving(false);
    }
  };

  const setEditingEnabled = () => {};
  
  // Contract Address Input Field component
  let contractAddressInputFieldComponent = null;

  if (network) {
    contractAddressInputFieldComponent = (
      <div className="bg-white border border-black mb-0.5 pb-1 pt-0.5 px-2 text-black">
        <div>
          <label className="text-xs">Contract Address</label>
          <input
              type="text"
              id="contractAddress"
              required={true}
              name="contractAddress"
              aria-label="Contract Address"
              className="bg-white focus:bg-neutral-100 focus:invalid:ring-cpg-red-500 focus:ring-0 invalid:text-cpg-red-500 -ml-1 -mt-1 outline-none px-1 py-0.5 placeholder:text-cpg-grey-1 rounded:none text-black w-full"
              placeholder="0x..."
              disabled={network === null}
              value={contractAddress}
              onChange={(e) => setContractAddress(e.target.value.trim())}
          />
        </div>
        { contractMetadata !== null &&
          <div className="flex flex-col gap-2 mt-1.5 pb-1">
            <div>
              <p className="text-xs">NFT Name:</p>
              <p>{`${contractMetadata.name}`}</p>
            </div>
            <div>
              <p className="text-xs">NFT Symbol:</p>
              <p>{`${contractMetadata.symbol}`}</p>
            </div>
            <div>
              <p className="text-xs">Token Standard:</p>
              <p>{`${contractMetadata.tokenType}`}</p>
            </div>
              <a
                className="active:bg-black active:border-black active:text-white border-b border-black duration-150 ease-linear hover:bg-cpg-blue-500 hover:border-cpg-blue-500 hover:text-white -mt-1.5 pt-1 w-fit"
                target="_blank"
                href={getOpenSeaUrlForCollection(ENV, network, contractMetadata.address)}>
                  View OpenSea Page &#8599;
              </a>
          </div>
        }
      </div>
    );
  }

  return (
    <div>

      {/* New Collection header and buttons */}
      <div className="flex flex-row gap-x-0.5 mb-0.5">
        <h1 className="flex-1 bg-black w-full text-white pt-1.5 pb-1 pl-2">
          New Collection
        </h1>
        {!editingEnabled && (
          <button
            onClick={() => setEditingEnabled(true)}
            className="text-white active:bg-white active:border active:border-black active:pt-[0.3125rem] active:pb-0.5 active:text-black bg-black pt-1.5 pb-1 hover:bg-cpg-blue-500 w-[4.5rem]"
          >
            Edit
          </button>
        )}
        {editingEnabled && (
          <>
            <button
              className="active:text-white active:bg-black active:border-black border border-black text-black bg-white pt-[0.3125rem] pb-0.5 hover:bg-cpg-blue-500 hover:border-cpg-blue-500 hover:text-white w-[4.5rem]"
              onClick={onCancel}
            >
              Cancel
            </button>
            <button
              onClick={saveForm}
              disabled={isSaving}
              className="text-white active:bg-white active:border active:border-black active:text-black bg-black pt-[0.3125rem] pb-0.5 hover:bg-cpg-blue-500 w-[4.5rem] disabled:bg-neutral-200 disabled:text-cpg-grey-1 disabled:border-neutral-200 disabled:shadow-none">
              {`${isSaving ? 'Saving...' : 'Save'}`}
            </button>
          </>
        )}
      </div>
      <div>
        {/* Collection Name */}
        <div className="bg-white border border-black mb-0.5 pb-1 pt-0.5 px-2 text-black">
          <label className="text-xs">Collection Name</label>
          <input
            type="text"
            id="name"
            required={true}
            name="name"
            aria-label="Collection Name"
            className="bg-white focus:bg-neutral-100 focus:invalid:ring-cpg-red-500 focus:ring-0 invalid:text-cpg-red-500 -ml-1 -mt-1 outline-none px-1 py-0.5 placeholder:text-cpg-grey-1 rounded:none text-black w-full"
            placeholder="Name"
            value={displayName}
            onChange={(e) => setDisplayName(e.target.value)}
          />
        </div>

        {/* Points Type */}
        <div className="bg-white border border-black mb-0.5 pb-1.5 pt-0.5 px-2 text-black">
          <label className="text-xs">Points Type</label>
          <div>
            <input
              type="radio"
              id="monthly"
              name="pointsType"
              className="accent-black mr-1"
              value="ACCUMULATE_MONTHLY"
              onChange={(e) => setPointsType(e.target.value)}
            />
            <label htmlFor="monthly">Monthly: Set points per month, accumulates daily</label>
          </div>
          <div>
            <input
              type="radio"
              id="fixed"
              name="pointsType"
              className="accent-black mr-1"
              value="FIXED"
              onChange={(e) => setPointsType(e.target.value)}
            />
            <label htmlFor="fixed">Fixed: One-time addition of points</label>
          </div>
        </div>

        {/* Points */}
        <div className="bg-white border border-black mb-0.5 pb-1 pt-0.5 px-2 text-black">
          <label className="text-xs">Points</label>
          <input
            type="number"
            id="points"
            required={true}
            name="points"
            aria-label="Points"
            className="bg-white focus:bg-neutral-100 focus:invalid:ring-cpg-red-500 focus:ring-0 invalid:text-cpg-red-500 -ml-1 -mt-1 outline-none px-1 py-0.5 placeholder:text-cpg-grey-1 rounded:none text-black w-full"
            placeholder="Points"
            min="0"
            value={points}
            onChange={(e) => setPoints(parseInt(e.target.value))}
          />
        </div>

        {/* Point Overrides */}
        <div className="bg-white border border-black flex flex-col gap-2 mb-0.5 p-0.5 text-black">
          <div className="pb-1 px-1.5">
            <div>
              <label className="text-xs">Point Overrides</label>
            </div>
            <input
                disabled
                type="checkbox"
                id="overridePointsForTokenIds"
                name="overridePointsForTokenIds"
                className="accent-black mr-1"
                checked={false}
            />
            <label
              htmlFor="overridePointsForTokenIds"
              className="text-cpg-grey-1" // Hardcode disabled grey text
            >Enabled</label>
            <p className="text-cpg-grey-1 text-xs">UI to configure coming soon</p>
          </div>
        </div>

        {/* Nerfing */}
        <div className="bg-white border border-black mb-0.5 pb-1.5 pt-0.5 px-2 text-black">
          <label className="text-xs">Nerfing</label>
          <div>
            <input
                type="checkbox"
                id="nerfingEnabled"
                name="nerfingEnabled"
                className="accent-black mr-1"
                checked={nerfingEnabled}
                onChange={(e) => setNerfingEnabled(e.target.checked)}
                disabled // Hardcode disabled
            />
            <label
              htmlFor="nerfingEnabled"
              className="text-cpg-grey-1" // Hardcode disabled grey text
            >Enabled</label>
            <p className="text-cpg-grey-1 text-xs">UI to configure coming soon</p>
          </div>
        </div>

        {/* Status */}
        <div className="bg-white border border-black mb-0.5 pb-1.5 pt-0.5 px-2 text-black">
          <label className="text-xs">Status</label>
          <div>
            <input
              type="checkbox"
              id="enabled"
              name="enabled"
              className="accent-black mr-1"
              checked={enabled}
              onChange={(e) => setEnabled(e.target.checked)}
            />
            <label htmlFor="enabled">Enabled</label>
          </div>
        </div>

        <h3 className="flex-1 bg-black w-full text-white mb-0.5 pt-1.5 pb-1 pl-2">
          Careful! The details below will not be editable later
        </h3>
        {/* Network */}
        <div className="bg-white border border-black mb-0.5 pb-1.5 pt-0.5 px-2 text-black">
          <label className="text-xs">Network</label>
          <div>
            <input
              type="radio"
              id="ethereum"
              name="network"
              className="accent-black mr-1"
              value="ETHEREUM"
              onChange={(e) => setNetwork('ETHEREUM')}
            />
            <label htmlFor="ethereum">Ethereum</label>
          </div>
          <div>
            <input
              type="radio"
              id="polygon"
              name="network"
              className="accent-black mr-1"
              value="POLYGON"
              onChange={(e) => setNetwork('POLYGON')}
            />
            <label htmlFor="polygon">Polygon</label>
          </div>
        </div>
        {contractAddressInputFieldComponent}
      </div>
    </div>
  );
}

CollectionAddForm.propTypes = {
  onCancel: PropTypes.func.isRequired,
  onAdd: PropTypes.func.isRequired
};
