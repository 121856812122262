/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-1",
    "aws_cloud_logic_custom": [
        {
            "name": "auth",
            "endpoint": "https://la9xevueqd.execute-api.us-east-1.amazonaws.com/staging",
            "region": "us-east-1"
        },
        {
            "name": "community",
            "endpoint": "https://z3hfkg7i9b.execute-api.us-east-1.amazonaws.com/staging",
            "region": "us-east-1"
        },
        {
            "name": "profile",
            "endpoint": "https://63u17amxlk.execute-api.us-east-1.amazonaws.com/staging",
            "region": "us-east-1"
        }
    ],
    "aws_content_delivery_bucket": "loyalty3-20221104132425-hostingbucket-staging",
    "aws_content_delivery_bucket_region": "us-east-1",
    "aws_content_delivery_url": "https://dxfpd4z289kug.cloudfront.net",
    "aws_dynamodb_all_tables_region": "us-east-1",
    "aws_dynamodb_table_schemas": [
        {
            "tableName": "asset-staging",
            "region": "us-east-1"
        },
        {
            "tableName": "collection-staging",
            "region": "us-east-1"
        },
        {
            "tableName": "community-staging",
            "region": "us-east-1"
        },
        {
            "tableName": "communityPOAPEvent-staging",
            "region": "us-east-1"
        },
        {
            "tableName": "profile-staging",
            "region": "us-east-1"
        },
        {
            "tableName": "wallet-staging",
            "region": "us-east-1"
        }
    ]
};


export default awsmobile;
