import React, {useEffect, useState} from "react";
import PropTypes from 'prop-types';
import {toast} from "react-hot-toast";
import {API} from "aws-amplify";
import {CPG_COMMUNITY_ID} from "../../utils/constants";
import useSession from "../../hooks/use-session";


export default function PointOverridesComponent({
                                                  overridePointsForTokenIds,
                                                  pointsTokenIdOverrideConfig,
                                                  setPointsTokenIdOverrideConfig,
                                                  contractAddress


    }) {
    const [newTokenId, setNewTokenId] = useState(NaN);
    const [newPointValue, setNewPointValue] = useState(NaN);
    const { sessionStore } = useSession();
    const { message, signature } = sessionStore.getUserSessionInfo();

    useEffect(() => {
        clearState();
    }, [overridePointsForTokenIds, pointsTokenIdOverrideConfig, contractAddress])

    function clearState() {
        setNewTokenId(NaN);
        setNewPointValue(NaN);
    }


    async function addPointOverride() {
        if (pointsTokenIdOverrideConfig.hasOwnProperty(newTokenId)) {
          toast.error('Token ID already has an override assigned, modify it directly.');
          return;
        }
        const updatedPointsTokenIdOverrideConfig = Object.assign({}, pointsTokenIdOverrideConfig,
          {
              [newTokenId]: parseInt(newPointValue)
          }
        );
        try {
            await API.put("community", `/community/${CPG_COMMUNITY_ID}/collection/${contractAddress}`, {
                response: true,
                body: {
                    pointsTokenIdOverrideConfig: updatedPointsTokenIdOverrideConfig
                },
                headers: {
                    "X-Cpg-Auth": [`m=${message}`, `s=${signature}`],
                },
            });
        } catch (error) {
            console.error('Error adding point override.', error);
            toast.error('Error adding point override. Please try again later.');
            return Promise.reject(error);
        }


        toast.success(`Points override added for token ID: ${newTokenId}`);
        setPointsTokenIdOverrideConfig(updatedPointsTokenIdOverrideConfig);
        clearState();
  }

  async function removePointOverride(tokenId) {
      const confirmDeleteTokenOverride = window.confirm('Are you sure you want to delete the following point override?\r\n\n Token IDs: ' + `${tokenId}` + '\r\n Points: ' + `${pointsTokenIdOverrideConfig[tokenId]}`);
      if (confirmDeleteTokenOverride) {

          let updatedPointsTokenIdOverrideConfig = Object.assign({}, pointsTokenIdOverrideConfig);
          delete updatedPointsTokenIdOverrideConfig[tokenId];
          try {
              await API.put("community", `/community/${CPG_COMMUNITY_ID}/collection/${contractAddress}`, {
                  response: true,
                  body: {
                      pointsTokenIdOverrideConfig: updatedPointsTokenIdOverrideConfig
                  },
                  headers: {
                      "X-Cpg-Auth": [`m=${message}`, `s=${signature}`],
                  },
              });
          } catch (error) {
              console.error('Error deleting point override.', error);
              toast.error('Error deleting point override. Please try again later.');
              return Promise.reject(error);
          }
          toast.success(`Points override deleted for token ID: ${tokenId}`);
          setPointsTokenIdOverrideConfig(updatedPointsTokenIdOverrideConfig);
      }

  }

  function renderOverride(tokenId) {
      return (
          <div className="bg-white border border-black flex flex-row gap-1 pb-0.5 pl-2 pr-1.5 pt-1.5 text-black" key={tokenId}>

              {/* STATIC WIP token IDs */}
              <div className="w-3/4">
                  <label className="text-xs">Token ID</label>
                  <input
                      type="text"
                      id="overrideTokenIds"
                      name="overrideTokenIds"
                      aria-label="Token IDs"
                      className="bg-white focus:bg-neutral-100 focus:invalid:ring-cpg-red-500 focus:ring-0 invalid:text-cpg-red-500 -ml-1 -mt-1 outline-none px-1 py-0.5 placeholder:text-cpg-grey-1 rounded:none text-black w-full"
                      placeholder="Enter token ID"
                      value={tokenId}
                      disabled
                  />
              </div>

              {/* STATIC WIP points */}
              <div className="w-1/4">
                  <label className="text-xs">Points</label>
                  <input
                      type="number"
                      id="tokenIdOverridePoints"
                      name="tokenIdOverridePoints"
                      aria-label="Points"
                      className="bg-white focus:bg-neutral-100 focus:invalid:ring-cpg-red-500 focus:ring-0 invalid:text-cpg-red-500 -ml-1 -mt-1 outline-none px-1 py-0.5 placeholder:text-cpg-grey-1 rounded:none text-black w-full"
                      placeholder="Set points"
                      value={pointsTokenIdOverrideConfig[tokenId]}
                      disabled
                  />
              </div>

              {/* Delete override */}
              <div
                  className="h-5 hover:cursor-pointer hover:bg-black active:bg-cpg-red-500 w-5"
                  onClick={() => removePointOverride(tokenId)}
              >
                  <svg viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg" className="fill-black h-5 hover:fill-white w-5" alt="Close">
                      <path d="M5.33333 15.8333L4.16667 14.6666L8.83333 9.99996L4.16667 5.33329L5.33333 4.16663L10 8.83329L14.6667 4.16663L15.8333 5.33329L11.1667 9.99996L15.8333 14.6666L14.6667 15.8333L10 11.1666L5.33333 15.8333Z"/>
                  </svg>
              </div>
          </div>
      )

  }

  const canClickAddPointsBtn = !isNaN(newTokenId) && newTokenId >= 0 && !isNaN(newPointValue) && newPointValue >= 0;

  if (overridePointsForTokenIds) {

    return (
      <div className="flex flex-col gap-0.5">
        <p className="px-1.5">Set point rewards for specific token IDs.</p>
        
        {
            pointsTokenIdOverrideConfig &&
            Object.keys(pointsTokenIdOverrideConfig)
                .sort((a,b) => a-b)
                .map(renderOverride)
        }

        <div className="bg-white border border-black flex flex-row gap-1 pb-0.5 pl-2 pr-[1.875rem] pt-1.5 px-2 text-black">
          <div className="w-3/4">
            <label className="text-xs">Token ID</label>
            <input
              type="number"
              id="overrideTokenIds"
              name="overrideTokenIds"
              aria-label="Token ID"
              className="bg-white focus:bg-neutral-100 focus:invalid:ring-cpg-red-500 focus:ring-0 invalid:text-cpg-red-500 -ml-1 -mt-1 outline-none px-1 py-0.5 placeholder:text-cpg-grey-1 rounded:none text-black w-full"
              placeholder="Enter token ID"
              value={newTokenId}
              min={0}
              onChange={(event) => setNewTokenId(event.target.value)}
            />
          </div>
          <div className="w-1/4">
            <label className="text-xs">Points</label>
            <input
              type="number"
              id="overrideTokenIds"
              name="overrideTokenIds"
              aria-label="Points"
              className="bg-white focus:bg-neutral-100 focus:invalid:ring-cpg-red-500 focus:ring-0 invalid:text-cpg-red-500 -ml-1 -mt-1 outline-none px-1 py-0.5 placeholder:text-cpg-grey-1 rounded:none text-black w-full"
              placeholder="Set points"
              min={0}
              step={1}
              value={newPointValue}
              onChange={(event) => setNewPointValue(Math.trunc(event.target.value))}
            />
          </div>
        </div>

        {/* Add Point Override button */}
        <button
          className="active:bg-white active:border-black active:text-black bg-black border border-transparent disabled:bg-neutral-200 disabled:text-cpg-grey-1 disabled:border-neutral-200 disabled:shadow-none hover:bg-cpg-blue-500 pb-0.5 pt-[0.3125rem] px-2 text-white w-fit"
          onClick={addPointOverride}
          disabled={!canClickAddPointsBtn}
        >
          Add Point Override
        </button>
      </div>
    )
  } else {
    return null;
  }
}

PointOverridesComponent.propTypes = {
  pointsTokenIdOverrideConfig: PropTypes.object,
  setPointsTokenIdOverrideConfig: PropTypes.func.isRequired,
  overridePointsForTokenIds: PropTypes.bool.isRequired,
    contractAddress: PropTypes.string.isRequired
};
