import React from "react";
import { useProfile } from "../../contexts/ProfileContext";

export default function LevelBar() {
    const { points, levelData } = useProfile();
    const { levelPointsLowerBound, levelPointsUpperBound, level } = levelData;

    const percentage = React.useMemo(() => {
      if (!levelPointsUpperBound || !points) return 0;
      if (levelPointsUpperBound === 0) return 0;
      if (levelPointsUpperBound === Number.MAX_SAFE_INTEGER) return 100;

      return (points / levelPointsUpperBound) * 100;
    }, [levelPointsUpperBound, points]);

    const displayedPoints = React.useMemo(() => {
      return points ? Math.floor(points).toString() : '0';
    }, [points]);

    const displayedUpperBound = React.useMemo(() => {
        return levelPointsUpperBound  === Number.MAX_SAFE_INTEGER ? levelPointsLowerBound : levelPointsUpperBound;
      }, [points]);

    return (
        <>
            <div className="bg-black flex flex-row justify-between mb-0.5">
                <p className="text-white pt-1.5 pb-1 pl-2">You have {displayedPoints} points</p>
                <p className="text-white pt-1.5 pb-1 pr-2">Level {level}: {displayedPoints}/{displayedUpperBound}</p>
            </div>

            <div className="bg-white border border-black h-8 mb-0.5 w-full">
                <div
                    className="bg-cpg-green-500 border-r border-r-black h-full"
                    style={{width: `${percentage}%`}}>
                </div>
            </div>
        </>
    );
}
