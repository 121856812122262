import { useState, useEffect } from "react";
import {useProfile} from "../../contexts/ProfileContext";
import {useAccount} from "wagmi";

export default function Quests() {
    const { profileId } = useProfile();
    const { address } = useAccount();


    const currentTime = new Date();
    const questEndTime = new Date('May 27, 2023 06:59:59 UTC');
    const [timeLeft, setTimeLeft] = useState(0);

    useEffect(() => {
    const timer = setInterval(() => {
        const currentDate = new Date();
        const timeDifference = questEndTime.getTime() - currentDate.getTime();

        // Calculate days, hours, and minutes
        const days = Math.floor(
            timeDifference / (1000 * 60 * 60 * 24)
        );
        const hours = Math.floor(
            (timeDifference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
        );
        const minutes = Math.floor(
            (timeDifference % (1000 * 60 * 60)) / (1000 * 60)
        );

        // Update state with time left
        setTimeLeft({ days, hours, minutes });
    }, 1000);

    return () => clearInterval(timer);

    }, []);

    if (currentTime < questEndTime) {
        return (
            <div className="bg-cpg-blue-500 text-white pb-8 pt-1.5 px-2 mb-0.5">
                <p><a className="active:bg-black active:border-black active:text-white border-b border-white duration-150 ease-linear hover:bg-white hover:border-white hover:text-black pt-1" href={`https://vr1puofpf3d.typeform.com/cpgawards#profileid=${profileId}&walletaddress=${address}`} target="_blank">Vote</a> in the next {timeLeft.days}d {timeLeft.hours}h {timeLeft.minutes}m on your favorite CPG Accelerator finalist. Winners will take home a portion of the $25K prize pool.</p>
            </div>
        );
    }

    return null;
}
