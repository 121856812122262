import { useRouteError } from "react-router-dom";
import Page from "../components/Page";
import Nav from "../components/Nav";

export default function Error() {
  const error = useRouteError();
  console.error(error);

  return (
    <Page>
      <div id="error-page">
        <Nav />
        <div className="bg-black mb-0.5 pb-1 pt-1.5 px-2 sm:w-nav text-white w-full">
          <h1>Oops! Sorry, an unexpected error has occurred.</h1>
        </div>
        <div className="bg-black pb-8 pt-1.5 px-2 sm:w-nav text-white w-full">
          <p>{error.statusText || error.message}</p>
        </div>
      </div>
    </Page>
  );
}
