import React, {useEffect, useState} from "react";
import { useSIWE } from "connectkit";
import { useNavigate } from "react-router-dom";
import Page from "../../components/Page";
import Nav from "../../components/Nav";
import DisplayCollectionsListView from "../../components/DisplayCollectionsListView";
import {useProfile} from "../../contexts/ProfileContext";
import useSession from "../../hooks/use-session";
import {API} from "aws-amplify";
import ViewCollectionDetails from "../../components/ViewCollectionDetails";
import CollectionAddForm from "../../components/CollectionAddForm";
import {toast} from "react-hot-toast";
const CPG_COMMUNITY_ID = "00000000-0000-0000-0000-000000000000";


function Tokens() {
  const [collections, setCollections] = useState([]);
  const [selectedCollection, setSelectedCollection] = useState(null);
  const [showAddCollectionForm, setShowAddCollectionForm] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const { address } = useSIWE();
  const { canAccessCommunityAdminDashboard } = useProfile();
  const { sessionStore } = useSession();
  const { message, signature } = sessionStore.getUserSessionInfo();
  const [isEditMode, setIsEditMode] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    // User is not signed in with ethereum
    if (!address || !canAccessCommunityAdminDashboard) {
      navigate("/");
    }
  }, [address, navigate]);

  const generateAndDownloadReport = async (e) => {
      const getReportPromise = API.post(
          "community",
          `/community/${CPG_COMMUNITY_ID}/report`, {
              headers: {
                  "X-Cpg-Auth": [`m=${message}`, `s=${signature}`],
              },
              timeout: 0 // unlimited timeout
          }
      );

      const resp = await toast.promise(getReportPromise, {
          loading: 'Generating report...',
          error: 'Error while generating report.'
      }, {loading: {duration: 20000}, success: {duration: 1}}); // weird quirk to get this to work properly

      toast((t) => (
              <div onClick={() => toast.dismiss(t.id)}>
                  <a href={resp.reportUrl} target={"_blank"} style={{ textDecoration: 'underline' }}>
                      Download here
                  </a>
                  <br/>
                  <br/>
                  <span className="bottom-0 right-0 text-xs">
                    <button>Dismiss</button>
                  </span>
              </div>
          ),
          {
              icon: '📁',
              duration: 1000 * 60 * 10 // 10 mins
          }
      );
  };


  useEffect(() => {
      if (!address || !canAccessCommunityAdminDashboard) {
          return;
      }
    const getCollections = async () => {
        setIsLoading(true);
      const getCollectionsPromise = API.get(
          "community",
          `/community/${CPG_COMMUNITY_ID}/collection`, {
            headers: {
              "X-Cpg-Auth": [`m=${message}`, `s=${signature}`],
            },
          }
      );

      const resp = await toast.promise(getCollectionsPromise, {
          loading: 'Loading...',
          error: 'Error while loading dashboard.'
      }, {loading: {duration: 20000}, success: {duration: 1}}); // weird quirk to get this to work properly

      setCollections(resp.collections);
      setIsLoading(false);
    };
    getCollections();
  }, []);

  let viewSelectedCollectionElement = null;
  if (selectedCollection !== null) {
      const onCollectionUpdate = (updatedCollection) => {
          setSelectedCollection(updatedCollection);
          setCollections(
              collections.map(c => {
                  if (c.contractAddress === updatedCollection.contractAddress)
                      return updatedCollection;
                  return c;
              })
          );
      };

      // View Collection details
      viewSelectedCollectionElement = (
          <div>
            <ViewCollectionDetails
              collection={selectedCollection}
              onUpdate={onCollectionUpdate}
              isEditMode={isEditMode}
              setIsEditMode={setIsEditMode}
            />
          </div>
      );
  }
  let viewAddCollectionFormElement = null;
  if (showAddCollectionForm) {

      const onAddCollectionComplete = (newCollection) => {
          setCollections(collections.concat(newCollection));
          setSelectedCollection(newCollection);
          setShowAddCollectionForm(false);
      };

      // Add Collection form
      viewAddCollectionFormElement = (
        <div>
          <CollectionAddForm onAdd={onAddCollectionComplete} onCancel={() => {
              setShowAddCollectionForm(false);
              setSelectedCollection(null);
          }}/>
        </div>
    );
  }

  return (
    <Page>
      <Nav />
      <section className="body-font">
        {/* Container div for admin content */}
        <div className="flex flex-col md:gap-0.5 md:flex-row">

          {/* Main admin nav */}
          <div className="flex flex-col gap-0.5 mb-0.5 md:w-1/6 w-full">
            <div className="bg-black border border-black flex flex-col hover:bg-cpg-blue-500 hover:cursor-pointer h-[4.125rem] hover:text-white pt-1.5 px-2 text-white w-full">
              Manage Tokens
            </div>
            <div className="bg-white border border-black flex flex-col hover:bg-cpg-blue-500 hover:cursor-pointer h-[4.125rem] hover:text-white pt-1.5 px-2 text-black w-full">
              Manage Team
            </div>
            <div className="bg-white border border-black flex flex-col hover:bg-cpg-blue-500 hover:cursor-pointer h-[4.125rem] hover:text-white pt-1.5 px-2 text-black w-full"
               onClick={generateAndDownloadReport}>
               Download Points Export
            </div>
          </div>

          {/* List of collections */}
          <div className="md:w-1/3 md:pb-0 w-full">
            <DisplayCollectionsListView
              collections={collections}
              setSelectedCollection={setSelectedCollection}
              showAddButton={!showAddCollectionForm}
              onAddButtonClick={() => {
                if (isEditMode) {
                  const confirmNavigateAway = window.confirm("Are you sure you want to navigate away? You will discard any changes.");
                  if (confirmNavigateAway) {
                    setIsEditMode(false);
                    setShowAddCollectionForm(true);
                    setSelectedCollection(null);
                  }
                } else {
                  setShowAddCollectionForm(true);
                  setSelectedCollection(null);
                }
              }}
              selectedCollection={selectedCollection}
              setShowAddCollectionForm={setShowAddCollectionForm}
              showAddCollectionForm={showAddCollectionForm}
              isEditMode={isEditMode}
              setIsEditMode={setIsEditMode}
            />
          </div>

          {/* Main content */}
          <div className="border-r border-transparent md:w-1/2 w-full">
            {viewSelectedCollectionElement}
            {viewAddCollectionFormElement}
          </div>
        </div>
      </section>
    </Page>
  );
}

export default Tokens;

