import { SiweMessage } from "siwe";
import { API } from "aws-amplify";
import { sessionStore } from "../hooks/use-session";

const HOMEPAGE_URL = process.env.REACT_APP_HOMEPAGE_WEBSITE_URL;

const siweConfig = {
  getNonce: async () => {
    const nonce = await API.get("auth", "/auth/nonce");
    return nonce;
  },
  createMessage: ({ nonce, address, chainId }) => {
    const message = new SiweMessage({
      version: "1",
      domain: window.location.host,
      uri: window.location.origin,
      address,
      chainId,
      nonce,
      statement: `By signing in with your wallet, you agree to the CPG Terms of Service (${HOMEPAGE_URL}/terms-of-service) and Privacy Policy (${HOMEPAGE_URL}/privacy-policy). This signature will not trigger any transactions in your wallet or cost any gas fees.`,
    });
    return message.prepareMessage();
  },
  verifyMessage: async ({ message, signature }) => {
    const options = {
      headers: {
        "Content-Type": "application/json",
      },
      body: { message, signature },
      response: true,
    };
    const res = await API.post("auth", "/auth/verify", options);
    const { m, s, ok } = res.data;
    const messageLines = message?.split("\n");

    const a = messageLines[1];
    const c = parseInt(messageLines[7]?.split("Chain ID: ")[1]);

    if (ok) {
      sessionStore.setUserSessionInfo(m, s, a, c);
      return ok;
    } else {
      console.error("Validation failed. Credentials are not valid");
    }
  },
  getSession: async () => {
    const { message, signature, address, chainId } =
      sessionStore.getUserSessionInfo();
    return message && signature ? { address, chainId } : null;
  },
  signOut: async () => {
    sessionStore.removeUserSessionInfo();
    return true;
  },
};

export default siweConfig;
