import PropTypes from 'prop-types';
import classNames from "classnames";

export default function DisplayCollectionsListView({
    showAddButton = true,
    setSelectedCollection,
    onAddButtonClick,
    collections,
    selectedCollection,
    showAddCollectionForm,
    setShowAddCollectionForm,
    isEditMode,
    setIsEditMode
  }) {
    
  return (
    <div>
      <div className="flex flex-row gap-x-0.5 mb-0.5">
        <h1 className="flex-1 bg-black w-full text-white pt-1.5 pb-1 pl-2">
          Collections
        </h1>
        {showAddButton && (
          <button
            className="text-white active:bg-white active:border active:border-black active:text-black bg-black border-transparent hover:bg-cpg-blue-500 hover:border-transparent pt-[0.3125rem] pb-0.5 w-[4.5rem]"
            onClick={onAddButtonClick}>
            Add
          </button>
        )}
      </div>

      <div>
        {collections.length > 0 &&
          collections.map(renderCollection)
        }
      </div>
    </div>
  );

  function renderCollection(collection, index) {
      if (!collection) {
          return null;
      }

      let pointsDisplay = 0;
      let pointsTypeLabel = '';
      if (collection.pointsType === "ACCUMULATE_MONTHLY") {
          pointsDisplay = collection.pointsPerMonth;
          pointsTypeLabel = 'Monthly';
      } else if (collection.pointsType === 'FIXED') {
          pointsDisplay = collection.fixedPoints;
          pointsTypeLabel = 'Fixed';
      }

      const isSelected = selectedCollection && selectedCollection.contractAddress === collection.contractAddress;

      return (
        <div
          key={index}
          onClick={() => {
            if (isEditMode || showAddCollectionForm) {
              const confirmNavigateAway = window.confirm("Are you sure you want to navigate away? You will discard any changes.");
              if (confirmNavigateAway) {
                // Set the specific collection to show and turn off edit mode
                setSelectedCollection(collection);
                // Hide the add collection form
                setShowAddCollectionForm(false);
                setIsEditMode(false);
              }
            } else {
              setSelectedCollection(collection);
              setShowAddCollectionForm(false);
            }
          }}
          className={classNames(`border border-black gap-x-1 h-[3.625rem] hover:bg-cpg-blue-500 hover:cursor-pointer hover:text-white mb-0.5 pt-1.5 px-2`, {
              'bg-black text-white': isSelected, 'bg-white text-black' : !isSelected
          })}>
            <h2 className="truncate">{collection.displayName}</h2>
            <p>{`${pointsTypeLabel} - ${pointsDisplay}`}</p>
        </div>
      );
  }
}

DisplayCollectionsListView.propTypes = {
    collections: PropTypes.array.isRequired,
    showAddButton: PropTypes.bool.isRequired,
    setSelectedCollection: PropTypes.func.isRequired,
    onAddButtonClick: PropTypes.func.isRequired,
    showAddCollectionForm: PropTypes.bool.isRequired,
    setShowAddCollectionForm: PropTypes.func.isRequired,
    selectedCollection: PropTypes.object,
    isEditMode: PropTypes.bool.isRequired,
    setIsEditMode: PropTypes.func.isRequired
};
