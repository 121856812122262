import React, { useEffect } from "react";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import { useAccount } from "wagmi";
import { useProfile } from "./contexts/ProfileContext";
import Main from "./routes/main";
import Profile from "./routes/profile";
import AdminTokens from "./routes/admin/tokens";
import Error from "./routes/error";
import "./index.css";

const router = createBrowserRouter([
  {
    path: "/",
    element: <Main />,
    errorElement: <Error />,
  },

  {
    path: "/profile",
    element: <Profile />,
    errorElement: <Error />,
  },

  {
    path: "/admin/tokens",
    element: <AdminTokens />,
    errorElement: <Error />,
  }
]);

function App() {
  const { address, isConnecting, isDisconnected } = useAccount();
  const { setConnectedAddress } = useProfile();

  useEffect(() => {
    setConnectedAddress(address);
  }, [setConnectedAddress, isConnecting, isDisconnected, address]);

  return <RouterProvider router={router} />;
}

export default App;
