import { Network, Alchemy } from "alchemy-sdk";

const ALCHEMY_API_KEY_MAINNET = process.env.REACT_APP_ALCHEMY_API_KEY;
const ALCHEMY_API_KEY_POLYGON = process.env.REACT_APP_ALCHEMY_POLYGON_API_KEY;

const env = process.env.REACT_APP_AMPLIFY_ENVIRONMENT;

const settingsMainnet = {
    apiKey: ALCHEMY_API_KEY_MAINNET,
    network: (env === 'prod') ? Network.ETH_MAINNET : Network.ETH_GOERLI,
};

const alchemyEthereumClient = new Alchemy(settingsMainnet);

const settingsPolygon = {
    apiKey: ALCHEMY_API_KEY_POLYGON,
    network: (env === 'prod') ? Network.MATIC_MAINNET : Network.MATIC_MUMBAI,
};

const alchemyPolygonClient = new Alchemy(settingsPolygon);

export default function useAlchemyClient() {
    return { alchemyEthereumClient, alchemyPolygonClient };
}
