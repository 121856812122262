import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useSIWE } from "connectkit";
import Page from "../components/Page";
import Nav from "../components/Nav";

const HOMEPAGE_URL = process.env.REACT_APP_HOMEPAGE_WEBSITE_URL;


function Main() {
  const { address } = useSIWE();
  const navigate = useNavigate();

  useEffect(() => {
    if (process.env.REACT_APP_AUTO_REDIRECT) {
      if (address) {
        navigate("/profile");
      }
    }
  }, [address, navigate]);

  return (
    <Page>
      <Nav />
      <div className="bg-black mb-0.5 pb-1 pt-1.5 px-2 sm:w-nav text-white w-full">
        <h1>CPG Rewards&trade;</h1>
      </div>
      <div className="bg-black mb-10 px-2 py-2 sm:w-nav text-white w-full">
        <p className="pb-8">Participate in CPG Rewards&trade; to earn collectibles and experience points. The more you level up, the more rewards.</p>
        <p className="text-white text-xs">By signing in with your wallet, you agree to the <a className="active:bg-black active:border-black active:text-white border-b border-white duration-150 ease-linear hover:bg-cpg-blue-500 hover:border-cpg-blue-500 hover:text-white pt-1" href={`${HOMEPAGE_URL}/terms-of-service`} target="_blank">Terms of Service</a> and <a className="active:bg-black active:border-black active:text-white border-b border-white duration-150 ease-linear hover:bg-cpg-blue-500 hover:border-cpg-blue-500 hover:text-white pt-1" href={`${HOMEPAGE_URL}/privacy-policy`} target="_blank">Privacy Policy</a>.</p>
      </div>
    </Page>
  );
}

export default Main;
