import { useProfile } from "../../contexts/ProfileContext";
import ProfileAddressList from "../ProfileAddressList";
import {useNetwork, useSignMessage} from 'wagmi';
import { useAccount } from 'wagmi';
import { SiweMessage } from "siwe";
import {API} from "aws-amplify";
import toast from 'react-hot-toast';
import useSession from "../../hooks/use-session";

export default function ProfileWallets() {
  const { address, isConnecting, isDisconnected } = useAccount();
  const { chain: activeChain } = useNetwork();
  const { wallets, profileId, setAddresses, setOwnedNFTs, setPoints, assets, points } = useProfile();
  const { sessionStore } = useSession();
  const { message, signature } = sessionStore.getUserSessionInfo();



  const walletOwnershipMsg = new SiweMessage({
    domain: window.location.host,
    address,
    statement: `Sign this message to prove your ownership of ${address}. By signing in with your wallet, you agree to the CPG Terms of Service (https://www.cryptopackagedgoods.com/terms-of-service) and Privacy Policy (https://www.cryptopackagedgoods.com/privacy-policy). This signature will not trigger any transactions in your wallet or cost any gas fees.`,
    uri: window.location.origin,
    version: "1",
    chain: activeChain?.id
  });
  const { data, isError, isLoading, isSuccess, signMessageAsync } = useSignMessage({
    message: walletOwnershipMsg.prepareMessage(),
  });

  const addNewWallet = async () => {
    if (wallets.find((wallet) => wallet.address.toLowerCase() === address.toLowerCase() )) {
      toast.error('Current wallet has already been linked to your profile. If you are trying to link another wallet, please switch to the new one within your wallet provider and try again.',
          {duration: 10000}
      );
      return;
    }

    let newWalletSignature = null;
    try {
      newWalletSignature = await signMessageAsync();
    } catch (error) {
      if (error.code === 4001) {
        console.debug('User rejected signature request', error);
        toast.error('You cancelled the signature request.');
      } else {
        console.error(`Failed to sign message:`, error);
        toast.error('Error performing signature request');
      }
      return Promise.reject(error);
    }

    const options = {
      headers: {
        "Content-Type": "application/json",
        "X-Cpg-Auth": [`m=${message}`, `s=${signature}`]
      },
      body: { message: walletOwnershipMsg, newWalletSignature, address, blockchain: 'EVM' },
      response: true,
    };

    try {
      const res = await API.post("profile", `/profile/${profileId}/add-wallet`, options);
      setAddresses(res.data.wallets);
      if (res.data.newAssets?.length > 0) {
        // add new wallet's assets to state as well.
        setOwnedNFTs(assets.concat(res.data.newAssets));
        //setPoints(res.data.newAssetsPoints + points);
        // TODO: dynamically update level
        toast.success(`Successfully added new wallet and staked ${res.data.newAssets.length} NFTs`, {
        duration: 7500 });
      } else {
        toast.success(`Successfully added new wallet`, {duration: 6000});
      }
    } catch (error) {
      console.error(`Failed to call add-wallet API:`, error);
      let errorMessage = 'Error adding wallet';
      if (error?.response?.data?.error) {
        errorMessage = `${error.response.data.error}`;
      }
      toast.error(errorMessage);
      return Promise.reject(error);
    }
  };

  const addWalletButton = (
      <button
        className="text-white active:bg-white active:border active:border-black active:text-black bg-black border-transparent hover:bg-cpg-blue-500 hover:border-transparent mb-0.5 pt-[0.3125rem] pb-0.5 w-[4.5rem]"
        onClick={() => addNewWallet()}>
        Add
      </button>
  );

  return (
    <div>
      <div className="flex flex-row gap-x-0.5">
        <h1 className="flex-1 bg-black w-full text-white mb-0.5 pt-1.5 pb-1 pl-2">Wallets</h1>
        {addWalletButton}
      </div>
      <ProfileAddressList wallets={wallets} />
    </div>
  );
}
