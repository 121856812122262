import {API} from "aws-amplify";
import toast from 'react-hot-toast';
import {useProfile} from "../../contexts/ProfileContext";
import useSession from "../../hooks/use-session";
import { useNavigate } from "react-router-dom";
import { useDisconnect } from 'wagmi'
import {useSIWE} from "connectkit";


export default function ProfileAddressList({ wallets }) {
  const { profileId, setAddresses, setOwnedNFTs, assets, clearContext } = useProfile();
  const { sessionStore } = useSession();
  const { message, signature } = sessionStore.getUserSessionInfo();
  const navigate = useNavigate();
  const { disconnect } = useDisconnect();
  const { signOut } = useSIWE();



  if (!wallets) return <></>;

  const shortAddress = (address) => {
    if (!address) return;
    return `${address.substring(0, 6)}…${address.substring(38)}`;
  };

  const forceSignOut = async () => {
    try {
      await signOut();  //  destroy the current SIWE session
    } catch (error) {
      console.error(error);
    }

    disconnect(); // disconnect wallet from wagmi
    clearContext(); // reset profile context
    navigate("/"); // take us to home page
  };

  const deleteAddress = async (address) => {
    if (wallets.length <= 1) {
      toast.error('Must have at least one wallet linked to your profile.');
      return;
    }

    const deletionConfirmed = prompt(`Are you sure you want to unlink ${address} from your profile?\n\nTo unlink this wallet, type DELETE below. This will unstake any NFTs inside, and you will permanently lose all points earned with them.`);
    if (!deletionConfirmed) {
      toast('Unlink wallet request cancelled.');
    } else if (deletionConfirmed.toUpperCase() !== "DELETE") {
      toast('To unlink a wallet, you must type DELETE in the prompt.');
    } else {
      
      console.debug("unlinking address", address);

      const options = {
        headers: {
          "Content-Type": "application/json",
          "X-Cpg-Auth": [`m=${message}`, `s=${signature}`],
        },
        body: { address },
        response: true
      };

      try {
        await API.del("profile", `/profile/${profileId}/remove-wallet`, options);
        // remove the deleted wallet and contents from state.
        setAddresses(wallets.filter(wallet => wallet.address.toLowerCase() !== address.toLowerCase()));
        setOwnedNFTs(assets.filter(asset => asset.ownerWalletId.toLowerCase() !== address.toLowerCase()));
        toast.success('Wallet unlinked from profile.');
        // if they are deleting the active wallet. force a sign out.
        if (address.toLowerCase() === sessionStore.getUserSessionInfo().address.toLowerCase()) {
          await forceSignOut();
        }
      } catch (error) {
        console.error(`Failed to call remove-wallet API:`, error);
        let errorMessage = 'Error unlinking wallet';
        if (error?.response?.data?.error) {
          errorMessage = `${error.response.data.error}`;
        }
        toast.error(errorMessage);
        return Promise.reject(error);
      }
    }

  };

  const addresses = wallets.map((wallet) => {
    return wallet.address;
  });

  const addressList = [];
  if (addresses) {
    for (let address of addresses) {
      let addressDisplay = (
          <div key={address}  className="bg-white border border-black flex justify-between mb-0.5 pb-[1.875rem] pt-1.5 px-2 text-black">
            <span>{shortAddress(address)}</span>
            <button
              type="button"
              className="w-5 h-5 hover:cursor-pointer hover:bg-black active:bg-cpg-red-500"
              onClick={() => deleteAddress(address)}>
              <svg viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg" className="w-5 h-5 fill-black hover:fill-white" alt="Delete">
                <path d="M5.33333 15.8333L4.16667 14.6666L8.83333 9.99996L4.16667 5.33329L5.33333 4.16663L10 8.83329L14.6667 4.16663L15.8333 5.33329L11.1667 9.99996L15.8333 14.6666L14.6667 15.8333L10 11.1666L5.33333 15.8333Z"/>
              </svg>
            </button>
          </div>
      );

      if (addresses.length === 1) {
        /* Do not allow deletion of last address */
        addressDisplay = (
            <div key={address} className="bg-white border border-black flex justify-between mb-0.5 pb-[1.875rem] pt-1.5 px-2 text-black">
              <span>{shortAddress(address)}</span>
            </div>
        )
      }
      addressList.push(addressDisplay);
    }
  }
  return (
    <div>
      <div>{addressList}</div>
    </div>
  );
}
