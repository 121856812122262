import PropTypes from 'prop-types';
import { mapPointsTypeIdsToFriendly } from '../../utils/helpers';
import React, {useEffect, useState} from "react";
import {toast} from "react-hot-toast";
import {API} from "aws-amplify";
import {CPG_COMMUNITY_ID} from "../../utils/constants";
import useSession from "../../hooks/use-session";
import classNames from 'classnames';
import PointOverridesComponent from '../PointOverrides';

export default function ViewCollectionDetails({
    collection,
    onUpdate,
    isEditMode,
    setIsEditMode
}) {

    const [isSaving, setIsSaving] = useState(false);
    const [displayName, setDisplayName] = useState(collection?.displayName);
    const [pointsType, setPointsType] = useState(collection?.pointsType);
    const [points, setPoints] = useState(collection?.pointsType === 'FIXED' ? collection?.fixedPoints : collection?.pointsPerMonth);
    const [enabled, setEnabled] = useState(collection?.enabled);
    const [overridePointsForTokenIds, setOverridePointsForTokenIds] = useState(collection?.overridePointsForTokenIds);
    const [pointsTokenIdOverrideConfig, setPointsTokenIdOverrideConfig] = useState(collection?.pointsTokenIdOverrideConfig);
    const [nerfingEnabled, setNerfingEnabled] = useState(collection?.nerfingEnabled);
    const [poapEvents, setPoapEvents] = useState([]);
    const [selectedEvent, setSelectedEvent] = useState(null);
    const [newPoapEventId, setNewPoapEventId] = useState('');
    const [newPoapEventPreview, setNewPoapEventPreview] = useState(null);
    const [isCreatingPoapEvent, setIsCreatingPoapEvent] = useState(false);
    const [isDeletingPoapEvent, setIsDeletingPoapEvent] = useState(false);

    const { sessionStore } = useSession();
    const { message, signature } = sessionStore.getUserSessionInfo();

    function resetFormInputFields() {
        setDisplayName(collection?.displayName);
        setPointsType(collection?.pointsType);
        setPoints(collection?.pointsType === 'FIXED' ? collection?.fixedPoints : collection?.pointsPerMonth);
        setEnabled(collection?.enabled);
        setOverridePointsForTokenIds(collection?.overridePointsForTokenIds);
        setNerfingEnabled(collection?.nerfingEnabled);
        setOverridePointsForTokenIds(collection?.overridePointsForTokenIds);
        setPointsTokenIdOverrideConfig(collection?.pointsTokenIdOverrideConfig);
    }

    const fetchPoapEventPreview = async () => {
        try {
            const response = await API.get('community', `/community/poap/${newPoapEventId}`, {
                response: true,
                headers: {
                    "X-Cpg-Auth": [`m=${message}`, `s=${signature}`],
                }
            });
            console.log('POAP Event preview response: ', response.data.poapEvent);
            setNewPoapEventPreview(response.data.poapEvent);
        } catch (error) {
            toast.error('Failed to fetch POAP preview. Please try again later.');
            console.error(error);
        }
    };

    const createPoapEvent = async () => {
        if (isCreatingPoapEvent) {
            return;
        }
        setIsCreatingPoapEvent(true);
        try {
            const response = await API.post('community', `/community/${CPG_COMMUNITY_ID}/poapevent`, {
                response: true,
                headers: {
                    "X-Cpg-Auth": [`m=${message}`, `s=${signature}`],
                },
                body: { poapEventId: newPoapEventId }
            });
            console.log('Create POAP Event response: ', response.data.poapEvent);
            const newPoapEvent = response.data.poapEvent;
            // add this new POAP event to our state.
            setPoapEvents(poapEvents.concat(newPoapEvent));
            // reset inputs
            setNewPoapEventId('');
            setNewPoapEventPreview(null);
            // set newly created poap event as currently selected.
            setSelectedEvent(newPoapEvent);
            toast.success(`Successfully added POAP ${newPoapEventId}`);
        } catch (error) {
            toast.error(`Failed to Create POAP ${newPoapEventId}. Please try again later.`);
            console.error(error);
        } finally {
            setIsCreatingPoapEvent(false);
        }
    };

    useEffect( () => {
        resetFormInputFields();

        const fetchPoapEvents = async () => {
            try {
                const response = await API.get('community', `/community/${CPG_COMMUNITY_ID}/poapEvent`, {
                    response: true,
                    headers: {
                        "X-Cpg-Auth": [`m=${message}`, `s=${signature}`],
                    }
                });
                console.log('Current POAP events in backend: ', response.data.poapEvents);
                setPoapEvents(response.data.poapEvents);
            } catch (error) {
                toast.error('Failed to fetch POAPs. Please try again later.');
                console.error(error);
            }
        };

        if (collection?.network === "POAP") {
            fetchPoapEvents();
        }
    }, [collection]);

    // Save form
    const saveForm = async () => {
        if (points < 0 || isNaN(points)) {
            toast.error('Invalid Points. Must be greater or equal to 0.');
            return;
        }
        if (displayName.length <= 0) {
            toast.error('Please provide a Collection Name.');
            return;
        }
        if (pointsType !== 'ACCUMULATE_MONTHLY' && pointsType !== 'FIXED') {
            toast.error('Invalid points type');
            return;
        }


        setIsSaving(true);

        try {
            const body = {
                pointsType,
                displayName: displayName.trim(),
                enabled,
                overridePointsForTokenIds
            };
            if (pointsType === 'FIXED') {
                body.fixedPoints = points;
                body.pointsPerMonth = 0;
            } else {
                body.fixedPoints = 0;
                body.pointsPerMonth = points;
            }
            await  API.put("community", `/community/${CPG_COMMUNITY_ID}/collection/${collection?.contractAddress}`, {
                response: true,
                body,
                headers: {
                    "X-Cpg-Auth": [`m=${message}`, `s=${signature}`],
                },
            });
            toast.success(`${displayName} updated successfully.`);
            setIsEditMode(false);
            return onUpdate(Object.assign({}, collection, body));
        } catch (error) {
            console.error('Failed to update new collection due to', error, error?.response?.data?.error);
            toast.error(error?.response?.data?.error);
        } finally {
            setIsSaving(false);
        }
    };


    let pointsTypeElement = (
        <div>
            {mapPointsTypeIdsToFriendly(pointsType)}
        </div>
    );
    let statusElement = (
        <div>
            {enabled ? 'Enabled' : 'Disabled'}
        </div>
    );
    if (isEditMode) {
        pointsTypeElement = (
            <>
                <div>
                    <input
                        type="radio"
                        id="monthly"
                        name="pointsType"
                        className="accent-black mr-1"
                        value="ACCUMULATE_MONTHLY"
                        onChange={(e) => setPointsType(e.target.value)}
                        checked={pointsType === 'ACCUMULATE_MONTHLY'}
                        disabled={!isEditMode}
                    />
                    <label htmlFor="monthly">Monthly: Set points per month, accumulates daily</label>
                </div>
                <div>
                    <input
                        type="radio"
                        id="fixed"
                        name="pointsType"
                        className="accent-black mr-1"
                        value="FIXED"
                        onChange={(e) => setPointsType(e.target.value)}
                        checked={pointsType === 'FIXED'}
                        disabled={!isEditMode}
                    />
                    <label htmlFor="fixed">Fixed: One-time addition of Points</label>
                </div>
            </>
        );

        statusElement = (
            <div>
                <input
                    type="checkbox"
                    id="enabled"
                    name="enabled"
                    className="accent-black mr-1"
                    checked={enabled}
                    onChange={(e) => setEnabled(e.target.checked)}
                    disabled={!isEditMode}
                />
                <label htmlFor="enabled">Enabled</label>
            </div>
        );
    }

    let saveButton = null;
    if (isEditMode) {
        saveButton = (
            <button
                className={classNames('text-white bg-black border-transparent disabled:hover:bg-black mb-0.5 pt-[0.3125rem] pb-0.5 px-3 hover:bg-cpg-blue-500 min-w-[4.5rem] w-fit', {
                    'active:bg-white active:border active:border-black active:text-black': !isSaving
                })}
                disabled={isSaving}
                onClick={saveForm}>
                {isSaving ? 'Saving...' : 'Save'}
            </button>
        );
    }

    let viewPoapEventsElement = null;
    let viewSelectedEventDetails = null;

    // Selected POAP event details
    if (selectedEvent !== null) {
        viewSelectedEventDetails = (
            <div className="bg-white border border-black p-2 relative">
                {/* Close details icon */}
                <div
                    onClick={() => {
                        setSelectedEvent(null);
                    }}
                    className="absolute h-5 hover:cursor-pointer hover:bg-black active:bg-cpg-blue-500 top-2 right-2 w-5">
                    <svg viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg" className="fill-black h-5 hover:fill-white w-5" alt="Close">
                        <path d="M5.33333 15.8333L4.16667 14.6666L8.83333 9.99996L4.16667 5.33329L5.33333 4.16663L10 8.83329L14.6667 4.16663L15.8333 5.33329L11.1667 9.99996L15.8333 14.6666L14.6667 15.8333L10 11.1666L5.33333 15.8333Z"/>
                    </svg>
                </div>
                <div className="flex flex-col lg:flex-row gap-6">
                    {/* POAP image */}
                    <div className="lg:w-1/4 w-1/3">
                        <label className="text-xs">Image</label>
                        <img
                            className="aspect-square rounded-full"
                            src={selectedEvent.poapImageUrl}
                            alt={selectedEvent.poapName}
                        />
                    </div>

                    <div className="flex flex-col gap-2 w-full">
                        {/* POAP event ID */}
                        <div>
                            <label className="text-xs">Event ID</label>
                            <p>{selectedEvent.poapEventId}</p>
                        </div>
                        {/* POAP title */}
                        <div>
                            <label className="text-xs">Title</label>
                            <p>{selectedEvent.poapName}</p>
                        </div>
                        {/* POAP link to gallery */}
                        <a
                            className="active:bg-black active:border-black active:text-white border-b border-black duration-150 ease-linear hover:bg-cpg-blue-500 hover:border-cpg-blue-500 hover:text-white pt-1 w-fit"
                            href={`https://poap.gallery/event/${selectedEvent.poapEventId}`}
                            target="_blank">
                                View in POAP Gallery &#8599;
                        </a>
                        {/* Delete POAP */}
                        <p
                            className="active:bg-black active:border-black active:text-white border-b border-black duration-150 ease-linear hover:bg-cpg-red-500 hover:border-cpg-red-500 hover:cursor-pointer hover:text-white mt-3 pt-1 text-sm w-fit"
                            onClick={() => deletePoapEvent(selectedEvent)}
                            >
                                Delete POAP
                        </p>
                    </div>
                </div>
            </div>
        )
    }

    // Delete POAP event
    async function deletePoapEvent(eventToDelete) {
        if (isDeletingPoapEvent) {
            // prevent multiple clicks
            return;
        }
        const deletePoapEventConfirmed = prompt(`Are you sure you want to delete the POAP for Event ${eventToDelete.poapEventId}?\n\nTo delete this POAP, type DELETE below. All members holding it will permanently lose their points for it.`);
        if (!deletePoapEventConfirmed) {
            toast('Delete POAP Event request cancelled.');
        } else if (deletePoapEventConfirmed.toUpperCase() !== "DELETE") {
            toast('To delete a POAP Event, you must type DELETE in the prompt.');
        } else {
            setIsDeletingPoapEvent(true);
            // DELETE POAP
            try {
                await API.del('community', `/community/${CPG_COMMUNITY_ID}/poapevent/${eventToDelete.poapEventId}`, {
                    response: true,
                    headers: {
                        "X-Cpg-Auth": [`m=${message}`, `s=${signature}`],
                    }
                });
                // remove poap event from state.
                setPoapEvents(poapEvents.filter(pe => pe.poapEventId !== eventToDelete.poapEventId))
                setSelectedEvent(null);
                toast.success(`POAP for Event ${eventToDelete.poapEventId} deleted.`)
            } catch (error) {
                console.error(error);
                toast.error('Failed to delete POAP. Please try again later.');
            } finally {
                setIsDeletingPoapEvent(false);
            }
        }
    }

    // Add new POAP event element
    let addEventElement = (
        <div className="flex flex-col gap-0.5">
            <label className="text-xs">Add a new event</label>
            <div className="flex flex-row gap-0.5">
                <div className="bg-white border border-black flex flex-row items-center lg:w-2/3 relative w-1/2">
                    {/* POAP event ID input */}
                    <input
                        type="text"
                        id="addPoapEventId"
                        name="addPoapEventId"
                        className="bg-white focus:ring-0 focus:bg-neutral-100 focus:invalid:ring-cpg-red-500 invalid:text-cpg-red-500 outline-none pb-0.5 pl-2 pr-1 pt-[0.3125rem] placeholder:text-cpg-grey-1 rounded:none text-black w-full"
                        placeholder="Event ID"
                        value={newPoapEventId}
                        onChange={(event) => {
                            setNewPoapEventId(event.target.value);
                            setNewPoapEventPreview(null);
                        }}
                    />
                    {/* Clear input icon */}
                    <div
                        className={classNames(`absolute h-5 hover:cursor-pointer hover:bg-black active:bg-cpg-blue-500 right-1 w-5`,
                            {"hidden": !newPoapEventPreview, "block": newPoapEventPreview}
                        )}
                        onClick={() => {
                            setNewPoapEventPreview();
                            setNewPoapEventId('');
                        }}
                        >
                        <svg viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg" className="fill-black h-5 hover:fill-white w-5" alt="Close">
                            <path d="M5.33333 15.8333L4.16667 14.6666L8.83333 9.99996L4.16667 5.33329L5.33333 4.16663L10 8.83329L14.6667 4.16663L15.8333 5.33329L11.1667 9.99996L15.8333 14.6666L14.6667 15.8333L10 11.1666L5.33333 15.8333Z"/>
                        </svg>
                    </div>
                </div>
                <button
                    className="active:bg-white active:border-black active:text-black bg-black border border-transparent disabled:bg-neutral-200 disabled:text-cpg-grey-1 disabled:border-neutral-200 disabled:shadow-none hover:bg-cpg-blue-500 lg:w-1/3 pb-0.5 pt-[0.3125rem] text-white w-1/2"
                    onClick={() => {
                        if (newPoapEventPreview) {
                            createPoapEvent();
                        } else {
                            fetchPoapEventPreview();
                        }
                    }}
                >
                    {newPoapEventPreview ? 'Add Event' : 'Preview'}
                </button>
            </div>

            {/* Add POAP event placeholder instructions */}
            {!newPoapEventPreview && (
                <div className="bg-white border border-black p-2">
                    <p>Enter an Event ID to see a preview here. You can find your Event ID from:</p>
                    <ul className="list-disc">
                        <li className="ml-4 px-2">
                            <span className="-ml-2">POAP’s confirmation email sent after creating the drop</span>
                        </li>
                        <li className="ml-4 px-2">
                            <span className="-ml-2">The “View in Gallery” link on the POAP token page</span>
                        </li>
                    </ul>
                </div>
            )}
            
            {/* New POAP preview */}
            {newPoapEventPreview && (
                <div className="bg-white border border-black p-2 relative">
                    <div className="flex flex-col lg:flex-row gap-6">
                        {/* POAP image */}
                        <div className="lg:w-1/4 w-1/3">
                            <label className="text-xs">Image</label>
                            <img
                                className="aspect-square rounded-full"
                                src={newPoapEventPreview.image_url}
                                alt={newPoapEventPreview.name}
                            />
                        </div>

                        <div className="flex flex-col gap-2 w-full">
                            {/* POAP event ID */}
                            <div>
                                <label className="text-xs">Event ID</label>
                                <p>{newPoapEventPreview.id}</p>
                            </div>
                            {/* POAP title */}
                            <div>
                                <label className="text-xs">Title</label>
                                <p>{newPoapEventPreview.name}</p>
                            </div>
                            {/* POAP link to gallery */}
                            <a
                                className="active:bg-black active:border-black active:text-white border-b border-black duration-150 ease-linear hover:bg-cpg-blue-500 hover:border-cpg-blue-500 hover:text-white pt-1 w-fit"
                                href={`https://poap.gallery/event/${newPoapEventPreview.id}`}
                                target="_blank">
                                    View in POAP Gallery &#8599;
                            </a>
                        </div>
                    </div>
                </div>
            )}
        </div>
    )

    // POAP events list element   
    if (!isEditMode && collection?.network === "POAP") {
        viewPoapEventsElement = (
            <div className="bg-white border border-black flex flex-col gap-2 pb-0.5 pt-0.5 px-0.5 text-black">
                <div className="px-1.5">
                    <label className="text-xs">Event IDs</label>
                    <p>Select an event to view more details</p>
                </div>

                <div className="flex flex-col gap-0.5">
                    {/* Grid of selectable events */}
                    <div className="grid grid-cols-2 lg:grid-cols-3 gap-0.5">
                        {poapEvents.map(event =>
                            <div
                                key={event.poapEventId}
                                // Style event differently if selected
                                className={classNames('active:bg-black border border-black hover:bg-cpg-blue-500 hover:border-transparent hover:cursor-pointer hover:text-white px-2 py-1.5',
                                    {'bg-black text-white': event.poapEventId === selectedEvent?.poapEventId,
                                    'bg-white text-black': event.poapEventId !== selectedEvent?.poapEventId}
                                )}
                                onClick={() => {
                                    setSelectedEvent(event);
                                }}>
                                {/* Event ID and name */}
                                <label className="text-xs">{event.poapEventId}</label>
                                <p className="truncate">{event.poapName}</p>
                            </div>
                        )}
                    </div>

                    {/* Display event details */}
                    {viewSelectedEventDetails}
                </div>

                {/* Add new event */}
                {addEventElement}
            </div>
        )
    }
    
    return (
        <div>
            {/* Collection details header and buttons */}
            <div className="flex flex-row gap-x-0.5 mb-0.5">
                <h2 className="flex-1 bg-black w-full text-white pt-1.5 pb-1 pl-2">
                    {collection?.displayName}
                </h2>
                <button
                    className={classNames('bg-black disabled:bg-neutral-200 disabled:text-cpg-grey-1 disabled:border-neutral-200 disabled:shadow-none hover:bg-cpg-blue-500 pt-[0.3125rem] pb-0.5 text-white w-[4.5rem]', {
                        'hidden': isSaving,
                        'active:bg-black active:border active:border-black active:text-white bg-white border border-black hover:border-cpg-blue-500 hover:text-white text-black': isEditMode,
                        'active:bg-white active:border active:border-black active:text-black': !isEditMode
                    })}
                    onClick={() => {
                        if (isEditMode) {
                            // reset any changes
                            resetFormInputFields();
                        }
                        setIsEditMode(!isEditMode);
                        setNewPoapEventId('');
                        setNewPoapEventPreview(null);
                    }}>
                    {isEditMode ? 'Cancel' : 'Edit'}
                </button>
                {saveButton}
            </div>
            <div className="flex flex-col gap-0.5">
                {/* Collection Name */}
                <div className="bg-white border border-black pb-1 pt-0.5 px-2 text-black">
                    <label className="text-xs">Collection Name</label>
                    <input
                        disabled={!isEditMode}
                        type="text"
                        id="name"
                        required={true}
                        name="name"
                        aria-label="Collection Name"
                        className="bg-white focus:bg-neutral-100 focus:invalid:ring-cpg-red-500 focus:ring-0 invalid:text-cpg-red-500 -ml-1 -mt-1 outline-none px-1 py-0.5 placeholder:text-cpg-grey-1 rounded:none text-black w-full"
                        placeholder="Name"
                        value={displayName}
                        onChange={(e) => setDisplayName(e.target.value)}
                    />
                </div>

                {/* Points Type */}
                <div className="bg-white border border-black pb-1.5 pt-0.5 px-2 text-black">
                    <label className="text-xs">Points Type</label>
                    {pointsTypeElement}
                </div>

                {/* Points */}
                <div className="bg-white border border-black pb-1 pt-0.5 px-2 text-black">
                    <label className="text-xs">Points</label>
                    <input
                        type="number"
                        id="points"
                        required={true}
                        name="points"
                        aria-label="Points"
                        className="bg-white focus:bg-neutral-100 focus:invalid:ring-cpg-red-500 focus:ring-0 invalid:text-cpg-red-500 -ml-1 -mt-1 outline-none px-1 py-0.5 placeholder:text-cpg-grey-1 rounded:none text-black w-full"
                        placeholder="Points"
                        min="0"
                        value={points}
                        onChange={(e) => setPoints(parseInt(e.target.value))}
                        disabled={!isEditMode}
                    />
                </div>

                {/* Show POAP events */}
                {viewPoapEventsElement}     

                {/* Point Overrides (hide if POAP) */}
                {collection?.network !== "POAP" && (
                    <div className="bg-white border border-black pt-0.5 px-0.5 text-black">
                        {!isEditMode && (
                            <div className="pb-1.5 px-1.5">
                                <label className="text-xs">Point Overrides</label>
                                <p>{overridePointsForTokenIds ? 'Enabled' : 'Disabled'}</p>
                            </div>
                        )}
                        {isEditMode && (
                            <div className="flex flex-col gap-1 pb-0.5">
                                <div className="pb-1 px-1.5">
                                    <div>
                                        <label className="text-xs">Point Overrides</label>
                                    </div>
                                    <input
                                        type="checkbox"
                                        id="overridePointsForTokenIds"
                                        name="overridePointsForTokenIds"
                                        className="accent-black mr-1"
                                        checked={overridePointsForTokenIds}
                                        onChange={(e) => {
                                            // If enabled, show dialog warning user they will lose all overrides
                                            if (overridePointsForTokenIds) {
                                                const confirmDisablePointsOverride = window.confirm('Are you sure you want to disable token ID point overrides? This will remove all overrides.')
                                                if (confirmDisablePointsOverride) {
                                                    setOverridePointsForTokenIds(e.target.checked);
                                                }
                                            } else {
                                                setOverridePointsForTokenIds(e.target.checked);
                                                if (!pointsTokenIdOverrideConfig) {
                                                    // TODO: save set override points for tokenid if enabled here for the first time because saving specific token overrides does not also save the enabling of the override feature itself.
                                                    setPointsTokenIdOverrideConfig({})
                                                }
                                            }
                                        }}
                                    />
                                    <label
                                        htmlFor="overridePointsForTokenIds"
                                    >Enabled</label>
                                </div>
                                <PointOverridesComponent
                                    overridePointsForTokenIds={overridePointsForTokenIds}
                                    pointsTokenIdOverrideConfig={pointsTokenIdOverrideConfig}
                                    setPointsTokenIdOverrideConfig={setPointsTokenIdOverrideConfig}
                                    contractAddress={collection?.contractAddress}
                                />
                            </div>
                        )}
                    </div>
                )}

                {/* Nerfing (hide if POAP) */}
                {collection?.network !== "POAP" && (
                    <div className="bg-white border border-black pb-1.5 pt-0.5 px-2 text-black">
                        <label className="text-xs">Nerfing</label>
                        <p className="text-cpg-grey-1">
                            {nerfingEnabled ? 'Enabled' : 'Disabled'}
                        </p>
                        {isEditMode && (
                            <p className="text-cpg-grey-1 text-xs">UI to edit coming soon</p>
                        )}
                    </div>
                )}           

                {/* Status */}
                <div className="bg-white border border-black pb-1.5 pt-0.5 px-2 text-black">
                    <label className="text-xs">Status</label>
                    {statusElement}
                </div>

                <div className="bg-white border border-black flex flex-col gap-2 pb-1.5 pt-0.5 px-2 text-black">
                    {/* Network */}
                    <div>
                        <label className="text-xs">Network</label>
                        <p className="text-cpg-grey-1">
                            {collection?.network}
                        </p>
                    </div>

                    {/* Contract Address */}
                    <div>
                        <label className="text-xs">Contract Address</label>
                        <p className="break-words text-cpg-grey-1">
                            {collection?.contractAddress}
                        </p>
                    </div>

                    {/* Token Standard */}
                    <div>
                        <label className="text-xs">Token Standard</label>
                        <p className="text-cpg-grey-1">
                            {collection?.tokenStandard}
                        </p>
                    </div>
                </div>
            </div>
        </div>
    );
}
ViewCollectionDetails.propTypes = {
    collection: PropTypes.object.isRequired,
    onUpdate: PropTypes.func.isRequired,
    isEditMode: PropTypes.bool.isRequired,
    setIsEditMode: PropTypes.func
};
