import { useState, useEffect } from "react";

const baseUrl = process.env.REACT_APP_ALCHEMY_BASE_URL;
const apiKey = process.env.REACT_APP_ALCHEMY_API_KEY;

export default function Token({ asset }) {
  const [imageUrl, setImageUrl] = useState('');
  const [tokenTitle, setTokenTitle] = useState('');
  const [metadataIsSet, setMetadataIsSet] = useState(false);

  useEffect(() => {
    const { contractAddress, tokenId, imageUrl, assetName, resourceStandard: tokenType } = asset;
    if (metadataIsSet) return;

    if (tokenType === 'POAP') {
      setImageUrl(asset.imageUrl);
      setTokenTitle(asset.assetName);
      setMetadataIsSet(true);
    } else if (imageUrl && assetName) {
      setImageUrl(asset.imageUrl);
      setTokenTitle(asset.assetName);
      setMetadataIsSet(true);
    } else {
      // TODO: move all metadata to asset resource vs fetching from alchemy
      const params = new URLSearchParams({
        contractAddress,
        tokenId,
        tokenType,
      });
      const getMetadata = async () => {
        const res = await (
            await fetch(`${baseUrl}${apiKey}/getNFTMetadata?${params}`)
        ).json();
        // use thumbnail for optimization if available.
        let imageUrl = res?.media[0].thumbnail || res?.media[0].gateway;
        // use CPG's IPFS gateway if possible, over public IPFS gateway for optimized loading
        if (imageUrl.startsWith('https://ipfs.io/ipfs/') && process.env.REACT_APP_AMPLIFY_ENVIRONMENT === 'prod') {
          imageUrl = imageUrl.replace('ipfs.io', 'cpg.mypinata.cloud');
        }

        setImageUrl(imageUrl);
        setTokenTitle(res?.title);
        setMetadataIsSet(true);
      };
      getMetadata();
    }


  }, [asset, metadataIsSet]);

  // TODO: show placeholder image if metadata fails to fetch

  const points = Math.floor(asset.points).toString() || '0';

  return (
    <div className="bg-white/30 backdrop-blur-sm relative border border-black w-full aspect-square">
      {metadataIsSet && !asset.isBlackListed && (
        <>
          <img
            alt={tokenTitle}
            className="w-full h-full object-cover"
            src={imageUrl}
          />
          <span className="absolute right-0 bottom-0 bg-black px-1 pt-1 pb-0.5 text-white text-[0.625rem]">
            {`${points} points`}
          </span>
        </>
      )}
    </div>
  );
}
