import { ConnectKitButton } from "connectkit";
export default function SIWEConnectButton() {
    return (
    <ConnectKitButton.Custom>
      {({ isConnected, show, truncatedAddress, ensName }) => {
        return (
          <button
            onClick={show}
            type="button"
            className="active:bg-white active:border active:border-black active:pb-5 active:text-black hover:bg-cpg-blue-500 inline-flex w-full sm:max-w-[13rem] bg-black pb-[1.375rem] pt-1.5 px-2 text-white"
            >
            {isConnected ? ensName ?? truncatedAddress : "Connect Wallet"}
          </button>
        );
      }}
    </ConnectKitButton.Custom>
  );
}
