
function mapPointsTypeIdsToFriendly(pointsTypeId) {
  switch (pointsTypeId) {
      case 'FIXED':
          return 'Fixed';
      case 'ACCUMULATE_MONTHLY':
          return 'Accumulate monthly';
      default:
          console.error('Invalid points type ID: ' + pointsTypeId);
          return 'UNRECOGNIZED_POINTS_TYPE';
  }
}

function getOpenSeaUrlForCollection(appEnv, network, collectionAddress) {
    return `https://${(appEnv === 'prod') ? '' : 'testnets.'}opensea.io/assets/${network}/${collectionAddress}`;
}

export {
    mapPointsTypeIdsToFriendly,
    getOpenSeaUrlForCollection
};
