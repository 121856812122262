export default class SessionStore {
  constructor() {
    this._message = null;
    this._signature = null;
    // try reading from browser storage if exists
    try {
      this._message = localStorage.getItem("cpg-m");
      this._signature = localStorage.getItem("cpg-s");
      this._address = localStorage.getItem("cpg-a");
      this._chainId = localStorage.getItem("cpg-c");
    } catch (error) {
      console.warn("Failed to check browser storage for session", error);
    }
  }

  getUserSessionInfo() {
    return {
      message: this._message,
      signature: this._signature,
      address: this._address,
      chainId: this._chainId,
    };
  }

  setUserSessionInfo(message, signature, address, chainId) {
    this._message = message;
    this._signature = signature;
    this._address = address;
    this._chainId = chainId;

    // store in browser storage to persist across sessions
    try {
      localStorage.setItem("cpg-m", message);
      localStorage.setItem("cpg-s", signature);
      localStorage.setItem("cpg-a", address);
      localStorage.setItem("cpg-c", chainId);
    } catch (error) {
      // can occur if user is using incognito mode or privacy shield.
      console.warn("Failed to set browser storage for session", error);
    }
  }

  removeUserSessionInfo() {
    // remove items from localStorage
    this._message = null;
    this._signature = null;
    this._address = null;
    this._chainId = null;
    try {
      localStorage.removeItem("cpg-m");
      localStorage.removeItem("cpg-s");
      localStorage.removeItem("cpg-a");
      localStorage.removeItem("cpg-c");
    } catch (error) {
      console.warn("Failed to remove browser storage for session", error);
    }
  }
}
