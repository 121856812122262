import { useState, useEffect } from "react";
import { API } from "aws-amplify";
import { useProfile } from "../contexts/ProfileContext";
import { useSIWE } from "connectkit";
import { useNavigate } from "react-router-dom";
import useSession from "../hooks/use-session";
import Page from "../components/Page";
import Nav from "../components/Nav";
import Quests from "../components/Quests";
import ProfileForm from "../components/ProfileForm";
import ProfileWallets from "../components/ProfileWallets";
import ProfileGallery from "../components/ProfileGallery";
import toast from 'react-hot-toast';

function Profile(props) {
  const { address } = useSIWE();
  const { setProfile, setAddresses, setProfileId, setOwnedNFTs, setPoints, setLevelData, setCanAccessCommunityAdminDashboard, clearContext } = useProfile();
  const { sessionStore } = useSession();
  const [isCallInProgress, setIsCallInProgress] = useState(false);
  const { message, signature } = sessionStore.getUserSessionInfo();

  const navigate = useNavigate();

  useEffect(() => {
    // User is not signed in with ethereum
    if (!address) {
      clearContext();
      navigate("/");
    }
  }, [address, navigate, clearContext]);

  useEffect(() => {
    if (!address) return;
    if (!message || !signature) return;
    if (isCallInProgress) return;
    // https://docs.amplify.aws/lib/restapi/fetch/q/platform/js/#get-requests
    const getProfileByAddress = async () => {
      setIsCallInProgress(true);

      try {
        const res = await API.post("profile", "/profile/by-address", {
          response: true,
          body: { address, hasAcceptedTermsAndPrivacyPolicy: true },
          headers: {
            "X-Cpg-Auth": [`m=${message}`, `s=${signature}`],
          },
        });
        setAddresses(res.data.wallets);
        setProfile(res.data.profile);
        setProfileId(res.data.profile.profileId);
        setOwnedNFTs(res.data.assets);
        setPoints(res.data.points);
        setLevelData(res.data.level)
        setCanAccessCommunityAdminDashboard(res.data.canAccessCommunityAdminDashboard === true);
      } catch (error) {
        console.error('Failed to fetch/create new profile', error);

        let errorMessage = 'Error loading profile.';
        if (error?.response?.data?.error) {
          errorMessage = `${error.response.data.error}`;
        }
        toast.error(errorMessage);
      } finally {
        // Seems to prevent multiple api calls
        setIsCallInProgress(false);
      }

    };
    getProfileByAddress();
  }, [
    setProfileId,
    setAddresses,
    setOwnedNFTs,
    address,
    setProfile,
    message,
    signature,
    setIsCallInProgress,
    setPoints
  ]);

  return (
    <Page>
      <Nav />
      <section className="body-font">
        <div className="flex flex-col">
          <div className="flex flex-col sm:flex-row">
            <div className="sm:w-2/5 md:min-w-1/3 mr-0 sm:mr-0.5 sm:max-w-nav order-2 sm:order-1">
              <div className="flex flex-col">
                <ProfileWallets />
                <ProfileForm />
              </div>
            </div>
            <div className="sm:w-3/5 md:min-w-2/3 md:w-full sm:pb-0 mb-0.5 order-1 sm:order-2">
              <Quests />
              <ProfileGallery />
            </div>
          </div>
        </div>
      </section>
    </Page>
  );
}

export default Profile;
