import { Link } from "react-router-dom";
import Logo from "../Logo";
import SIWEConnectButton from "../SIWEConnectButton";
import {useProfile} from "../../contexts/ProfileContext";

export default function Nav() {
    const { canAccessCommunityAdminDashboard } = useProfile();

    return (
    <nav className="flex flex-col mb-0.5 pt-3 sm:flex-row gap-0.5 text-white">
      <Link to={"/"} className="">
        <Logo />
      </Link>
      <SIWEConnectButton />
      <a
        href="https://cryptopackagedgoods.notion.site/CPG-Rewards-FAQ-fd07845234074b75a223ced62c575846"
        rel="noreferrer noopener"
        target="_blank"
        className="active:bg-white active:border active:border-black active:text-black hover:bg-cpg-blue-500 align-text-top bg-black pb-5 sm:pb-0 pt-1.5 px-2 sm:max-w-[13rem] w-full"
      >
        FAQs
      </a>
        {canAccessCommunityAdminDashboard &&
            <Link
                to={"/admin/tokens"}
                className="active:bg-white active:border active:border-black active:text-black hover:bg-cpg-blue-500 align-text-top bg-black pb-5 sm:pb-0 pt-1.5 px-2 sm:max-w-[13rem] w-full"
            >
                Admin
            </Link>
        }
    </nav>
  );
}
