import React, {useEffect, useState} from 'react';
import { API } from "aws-amplify";
import toast from 'react-hot-toast';
import {useProfile} from "../../contexts/ProfileContext";
import useSession from "../../hooks/use-session";


export default function TwitterOAuth () {
    const { sessionStore } = useSession();
    const { setProfile, profile, profileId } = useProfile();


    const [loading, setLoading] = useState(false);


    useEffect( () => {
        async function storeTwitterOAuthCreds() {
            const params = new URLSearchParams(window.location.search);
            const twitterOAuthToken = params.get('oauth_token');
            const twitterOAuthVerifier = params.get('oauth_verifier');

            if (twitterOAuthToken && twitterOAuthVerifier) {
                setLoading(true);
                const { message, signature } = sessionStore.getUserSessionInfo();
                try {
                    const resp = await API.put('profile', `/profile/${profileId}/twitter`, {
                        response: true,
                        body: { twitterOAuthVerifier, twitterOAuthToken },
                        headers: {
                            "X-Cpg-Auth": [`m=${message}`, `s=${signature}`],
                        },
                    });
                    toast.success('Successfully connected Twitter account.');
                    // remove all twitter oauth related query params
                    window.history.replaceState(null, null, window.location.pathname);
                    const { twitterHandle, twitterUserId } = resp.data;
                    setProfile(Object.assign(profile, { twitterHandle, twitterUserId }));
                } catch (error) {
                    toast.error('Failed to save Twitter credentials.');
                    console.error('Failed to save Twitter credentials');
                } finally {
                    setLoading(false);
                }
            }
        }
        storeTwitterOAuthCreds();

    }, []);


    const handleClick = async (e) => {
        if (profile.twitterHandle) {
            e.preventDefault();
            return;
        }

        setLoading(true);
        try {
            const { authUrl } = await API.post('auth', '/auth/get-twitter-auth-token');
            //setAuthUrl(authUrl);
            if (authUrl) {
                window.location.href = authUrl;
            } else {
                console.error('Failed to fetch twitter request token');
                toast.error('Failed to connect Twitter account');
            }
        } catch (error) {
            toast.error('Failed to connect Twitter account');
            console.error('Failed to fetch token', error);
        } finally {
            setLoading(false);
        }
    };

    let connectTwitterInputValue = 'Connect Twitter';

    if (loading) {
        connectTwitterInputValue = 'Loading...'
    } else if (profile.twitterHandle) {
        connectTwitterInputValue = profile.twitterHandle;
    }

    return (
        // Render the login button, and handle the redirect to the authorization page
        <div className="bg-white border border-black flex flex-row gap-x-1 mb-0.5 pb-[1.625rem] pt-0.5 px-2 text-black"
             onClick={handleClick}
                style={{'cursor': 'pointer'}}>
            <svg
                viewBox="0 0 20 20"
                fill="currentColor"
                xmlns="http://www.w3.org/2000/svg"
                className="w-5 h-5 mt-[0.3125rem] flex-none"
            >
                <path
                    d="M19.7025 4.11415C19.0067 4.42248 18.2592 4.63082 17.4733 4.72498C18.2842 4.23981 18.8908 3.4762 19.18 2.57665C18.4182 3.02914 17.5845 3.34765 16.715 3.51832C16.1303 2.89404 15.3559 2.48025 14.5119 2.34121C13.668 2.20217 12.8017 2.34564 12.0477 2.74936C11.2936 3.15308 10.694 3.79445 10.3418 4.5739C9.98961 5.35335 9.90461 6.22727 10.1 7.05998C8.55641 6.98248 7.04636 6.58127 5.66785 5.8824C4.28935 5.18353 3.0732 4.20262 2.09833 3.00332C1.765 3.57832 1.57333 4.24498 1.57333 4.95498C1.57296 5.59415 1.73036 6.22352 2.03157 6.78726C2.33277 7.351 2.76847 7.83168 3.3 8.18665C2.68356 8.16704 2.08073 8.00047 1.54167 7.70082V7.75082C1.54161 8.64727 1.85169 9.51613 2.41932 10.21C2.98695 10.9038 3.77714 11.3799 4.65583 11.5575C4.08399 11.7122 3.48445 11.735 2.9025 11.6242C3.15042 12.3955 3.63333 13.07 4.28364 13.5533C4.93395 14.0365 5.7191 14.3043 6.52917 14.3192C5.15403 15.3987 3.45574 15.9842 1.7075 15.9816C1.39782 15.9817 1.0884 15.9636 0.780834 15.9275C2.5554 17.0685 4.62112 17.674 6.73083 17.6716C13.8725 17.6716 17.7767 11.7566 17.7767 6.62665C17.7767 6.45998 17.7725 6.29165 17.765 6.12498C18.5244 5.57579 19.1799 4.89573 19.7008 4.11665L19.7025 4.11415V4.11415Z"
                    fill="black"
                />
            </svg>
            <input
                type="text"
                id="twitterHandle"
                name="twitterHandle"
                aria-label="Twitter"
                className="bg-white placeholder:text-cpg-grey-1 rounded:none p-1 text-black focus:ring-0 focus:bg-neutral-100 outline-none w-full invalid:text-cpg-red-500 focus:invalid:ring-cpg-red-500"
                minLength="2"
                maxLength="16"
                value={connectTwitterInputValue}
                style={{'cursor': 'pointer'}}
                readOnly
            />
        </div>
    );
};
