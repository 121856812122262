import React, { useState, useEffect, useMemo } from "react";
import { API } from "aws-amplify";
import { useProfile } from "../../contexts/ProfileContext";
import useSession from "../../hooks/use-session";
import toast from 'react-hot-toast';
import ConnectTwitterButton from '../ConnectTwitterButton';
import PhoneInput from 'react-phone-number-input';
import { isPossiblePhoneNumber } from 'react-phone-number-input'
import 'react-phone-number-input/style.css'
import '../../styles/phoneInput.css'


export default function ProfileForm() {
  const { profile, profileId, setProfile } = useProfile();
  const [profileInfo, setProfileInfo] = useState({});
  const [phoneNumber, setPhoneNumber] = useState('');
  const [profileIsSet, setProfileIsSet] = useState(false);
  const [editingEnabled, setEditingEnabled] = useState(false);
  const [tempProfile, setTempProfile] = useState({});
  const [inputError, setInputError] = useState(false);
  const { sessionStore } = useSession();

  const emailIsNotSet = useMemo(() => {
    return !profileInfo || !profileInfo.email || profileInfo.email.trim() === ''
  }, [profileInfo?.email]);

  useEffect(() => {
    if (profileIsSet) return;
    if (!profile) return;
    const profileObj = {
      email: profile.email,
      phoneNumber: profile.phoneNumber,
      telegramHandle: profile.telegramHandle,
      hasConsentedToEmail: profile.hasConsentedToEmail
    };
    setPhoneNumber(profile.phoneNumber);
    setProfileInfo(profileObj);
    setTempProfile(profileObj);
    setProfileIsSet(true);
  }, [profile, setProfileInfo, setProfileIsSet, profileIsSet]);

  if (!profile) return <></>;

  const { message, signature } = sessionStore.getUserSessionInfo();

  const handleChange = (event) => {
    if (!editingEnabled) return;
    let value = event.target.value;
    if (event.target.type === 'checkbox') {
      value = event.target.checked;
    }
    if (event.target.validity.patternMismatch) {
      setInputError(true);
    } else {
      setInputError(false);
    }
    setProfileInfo({ ...profileInfo, [event.target.name]: value });
  };

  // TODO: Save form does not do validation check
  const saveForm = async () => {
    const body = {
      email: profileInfo.email,
      hasConsentedToEmail: profileInfo.hasConsentedToEmail,
      phoneNumber: phoneNumber,
      telegramHandle: profileInfo.telegramHandle,
    };
    try {
      await API.put("profile", `/profile/${profileId}`, {
        response: true,
        body,
        headers: {
          "X-Cpg-Auth": [`m=${message}`, `s=${signature}`],
        },
      });
      setTempProfile(body);
      setProfile(Object.assign({}, profile, body));
      setEditingEnabled(false);
      toast.success('Profile updated!')
    } catch (error) {
      console.error('Failed to save profile');
      toast.error('Error updating profile.')
    }

  };

  const cancelEditing = () => {
    setProfileInfo(tempProfile);
    setPhoneNumber(profile.phoneNumber || '');
    setEditingEnabled(false);
  };

  return (
    <div>
      <div className="flex flex-row gap-x-0.5">

        {/* Header and buttons */}
        <h1 className="flex-1 bg-black w-full text-white mb-0.5 pt-1.5 pb-1 pl-2">
          Profile
        </h1>
        {!editingEnabled && (
          <button
            onClick={() => setEditingEnabled(true)}
            className="text-white active:bg-white active:border active:border-black active:pt-[0.3125rem] active:pb-0.5 active:text-black bg-black mb-0.5 pt-1.5 pb-1 hover:bg-cpg-blue-500 w-[4.5rem]"
          >
            Edit
          </button>
        )}
        {editingEnabled && (
          <>
            <button
              onClick={cancelEditing}
              className="active:text-white active:bg-black border border-black text-black bg-white hover:bg-cpg-blue-500 hover:border-transparent hover:text-white mb-0.5 pt-[0.3125rem] pb-0.5 w-[4.5rem]"
            >
              Cancel
            </button>
            <button
              onClick={saveForm}
              disabled={inputError || emailIsNotSet || (phoneNumber && !isPossiblePhoneNumber(phoneNumber))}
              className="text-white active:bg-white active:border active:border-black active:text-black bg-black border border-transparent disabled:bg-neutral-200 disabled:border-transparent disabled:shadow-none disabled:text-cpg-grey-1 hover:bg-cpg-blue-500 hover:border-transparent mb-0.5 pt-[0.3125rem] pb-0.5 w-[4.5rem]"
            >
              Save
            </button>
          </>
        )}
      </div>

      {/* Email */}
      <div>
        <div className="bg-white border border-black flex flex-col mb-0.5 pb-[1.375rem] pt-0.5 px-2 text-black">
          <div className="flex flex-row gap-1 mb-1">
            <svg
              viewBox="0 0 20 20"
              fill="currentColor"
              xmlns="http://www.w3.org/2000/svg"
              className="w-5 h-5 mt-[0.3125rem] flex-none"
            >
              <path
                d="M18.3333 3.33331H1.66667V16.6666H18.3333V3.33331ZM16.6667 6.66665L10 10.8333L3.33333 6.66665V4.99998L10 9.16665L16.6667 4.99998V6.66665Z"
                fill="black"
              />
            </svg>
            <input
              type="text"
              id="email"
              pattern="[a-zA-Z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$"
              required={true}
              name="email"
              aria-label="Email"
              className="bg-white placeholder:text-cpg-grey-1 rounded:none p-1 text-black focus:ring-0 focus:bg-neutral-100 outline-none w-full invalid:text-cpg-red-500 focus:invalid:ring-cpg-red-500"
              placeholder={!editingEnabled ? "No email added" : "hi@example.com"}
              value={profileInfo.email || ''}
              onChange={handleChange}
              disabled={!editingEnabled}
            />
          </div>
          {/* Only show email consent checkbox if user has not already opted in. Since opting out here won't remove them from emails, it isn't helpful to show otherwise, and they can opt out directly via email. */}
          {!profile.hasConsentedToEmail && editingEnabled && (
            <div>
              <input
                  type="checkbox"
                  id="hasConsentedToEmail"
                  name="hasConsentedToEmail"
                  aria-label="Email consent checkbox"
                  className="accent-black ml-1 mr-2.5"
                  onChange={handleChange}
                  disabled={!editingEnabled}
                  checked={profileInfo.hasConsentedToEmail}
              />
              <label htmlFor="hasConsentedToEmail">Receive emails from CPG</label>
            </div>
          )}
        </div>

        {/* Phone number */}
        <div className="bg-white border border-black flex flex-row gap-x-1 mb-0.5 pb-[1.625rem] pt-0.5 px-2 text-black">
          
          { /* Displays custom icon and text if editing is not enabled */
          !editingEnabled && (
            <div className="flex flex-row gap-x-1 mb-1">
              <svg
                viewBox="0 0 20 20"
                  fill="currentColor"
                  xmlns="http://www.w3.org/2000/svg"
                  className="w-5 h-5 mt-[0.3125rem] flex-none"
                >
                <path
                  d="M17.0725 14.2833L13.685 11.2033C13.5249 11.0578 13.3145 10.9802 13.0982 10.9869C12.8819 10.9935 12.6767 11.084 12.5258 11.2392L10.5317 13.29C10.0517 13.1983 9.08667 12.8975 8.09334 11.9067C7.10001 10.9125 6.79917 9.945 6.71001 9.46833L8.75917 7.47333C8.91452 7.3226 9.00512 7.11735 9.01181 6.901C9.0185 6.68466 8.94074 6.4742 8.79501 6.31416L5.71584 2.9275C5.57004 2.76696 5.36741 2.66958 5.15097 2.65605C4.93453 2.64251 4.72134 2.71388 4.55667 2.855L2.74834 4.40583C2.60427 4.55043 2.51827 4.74287 2.50667 4.94666C2.49417 5.155 2.25584 10.09 6.08251 13.9183C9.42084 17.2558 13.6025 17.5 14.7542 17.5C14.9225 17.5 15.0258 17.495 15.0533 17.4933C15.2571 17.4819 15.4494 17.3955 15.5933 17.2508L17.1433 15.4417C17.285 15.2775 17.3569 15.0645 17.3437 14.8481C17.3304 14.6317 17.2331 14.429 17.0725 14.2833Z"
                  fill="black"
                />
              </svg>
              {!phoneNumber && (
                <p className="mt-1 ml-1 text-cpg-grey-1">Add phone number</p>
              )}
              {phoneNumber && (
                <p className="mt-1 ml-1">{phoneNumber}</p>
              )}
            </div>
          )}
          
          { /* Renders PhoneInput component form when editing is enabled */
          editingEnabled && (
            <PhoneInput
              name="phoneInputWithCountrySelect"
              onChange={(newNum) => {setPhoneNumber(newNum)}}
              placeholder="Enter phone number"
              value={phoneNumber}
              defaultCountry="US"
              disabled={!editingEnabled}
              className="w-full"
              style={{
                '--PhoneInput-color--focus': '#000000',
                '--PhoneInputCountryFlag-borderColor': '#ffffff',
                '--PhoneInputCountryFlag-height': '13px'
              }}
            />
          )}
        </div>

        {/* Telegram */}
        <div className="bg-white border border-black flex flex-row gap-x-1 mb-0.5 pb-[1.625rem] pt-0.5 px-2 text-black">
          <svg
            viewBox="0 0 20 20"
            fill="currentColor"
            xmlns="http://www.w3.org/2000/svg"
            className="w-5 h-5 mt-[0.3125rem] flex-none"
          >
            <g clipPath="url(#clip0_205_2418)">
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M20 10C20 15.5225 15.5225 20 10 20C4.4775 20 0 15.5225 0 10C0 4.4775 4.4775 0 10 0C15.5225 0 20 4.4775 20 10ZM10.3583 7.3825C9.38583 7.78667 7.44167 8.62417 4.52667 9.89417C4.05333 10.0825 3.805 10.2667 3.7825 10.4467C3.74417 10.7517 4.12583 10.8717 4.64417 11.0342C4.715 11.0567 4.78833 11.0792 4.86333 11.1042C5.37417 11.27 6.06083 11.4642 6.4175 11.4717C6.74167 11.4783 7.10333 11.345 7.5025 11.0717C10.2258 9.2325 11.6317 8.30333 11.72 8.28333C11.7825 8.26917 11.8692 8.25083 11.9275 8.30333C11.9858 8.355 11.98 8.45333 11.9742 8.48C11.9358 8.64083 10.4408 10.0317 9.66583 10.7517C9.42417 10.9758 9.25333 11.135 9.21833 11.1717C9.14 11.2525 9.06 11.33 8.98333 11.4042C8.50833 11.8608 8.15333 12.2042 9.00333 12.7642C9.41167 13.0333 9.73833 13.2558 10.0642 13.4775C10.42 13.72 10.775 13.9617 11.235 14.2633C11.3517 14.34 11.4633 14.4192 11.5725 14.4967C11.9867 14.7925 12.3592 15.0575 12.8192 15.0158C13.0858 14.9908 13.3625 14.74 13.5025 13.9908C13.8333 12.2192 14.485 8.3825 14.6358 6.80083C14.645 6.66951 14.6394 6.53757 14.6192 6.4075C14.607 6.30244 14.5559 6.20578 14.4758 6.13667C14.3567 6.03917 14.1717 6.01833 14.0883 6.02C13.7125 6.02667 13.1358 6.2275 10.3583 7.3825V7.3825Z"
                fill="black"
              />
            </g>
            <defs>
              <clipPath id="clip0_205_2418">
                <rect width="20" height="20" fill="currentColor" />
              </clipPath>
            </defs>
          </svg>
          <input
            type="text"
            pattern="^@[a-zA-Z0-9_]+$"
            id="telegramHandle"
            name="telegramHandle"
            aria-label="Telegram"
            className="bg-white placeholder:text-cpg-grey-1 rounded:none p-1 text-black focus:ring-0 focus:bg-neutral-100 outline-none w-full invalid:text-cpg-red-500 focus:invalid:ring-cpg-red-500"
            placeholder={!editingEnabled ? "No Telegram added" : "@telegram (include @)"}
            minLength="6"
            maxLength="33"
            value={profileInfo.telegramHandle || ''}
            onChange={handleChange}
            disabled={!editingEnabled}
          />
        </div>

        {/* Twitter  */}
        <ConnectTwitterButton/>
      </div>
    </div>
  );
}
