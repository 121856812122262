export const SET_PROFILE = "set-profile";
export const SET_CONNECTED_ADDRESS = "set-connected-address";
export const SET_OWNED_NFTS = "set-owned-nfts";
export const SET_ADDRESSES = "set-addresses";
export const SET_PROFILE_ID = "set-profile-id";
export const SET_POINTS = "set-points";
export const SET_LEVEL_DATA = 'set-level-data';
export const CPG_AUTH_HEADER = "X-Cpg-Auth";
export const CPG_COMMUNITY_ID = "00000000-0000-0000-0000-000000000000";
export const SET_CAN_ACCESS_COMMUNITY_ADMIN_DASHBOARD = 'set-can-access-community-admin-dashboard';
