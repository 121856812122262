import {
  createContext,
  useContext,
  useCallback,
  useMemo,
  useReducer,
} from "react";
import {
  SET_PROFILE,
  SET_CONNECTED_ADDRESS,
  SET_OWNED_NFTS,
  SET_ADDRESSES,
  SET_PROFILE_ID,
  SET_POINTS,
  SET_LEVEL_DATA,
  SET_CAN_ACCESS_COMMUNITY_ADMIN_DASHBOARD
} from "../utils/constants";

const ProfileContext = createContext();

const profileReducer = (state, event) => {
  switch (event.type) {
    case SET_PROFILE:
      return {
        ...state,
        profile: event.payload,
      };
    case SET_CONNECTED_ADDRESS:
      return {
        ...state,
        address: event.payload,
      };
    case SET_OWNED_NFTS:
      return {
        ...state,
        assets: event.payload,
      };
    case SET_ADDRESSES:
      return {
        ...state,
        wallets: event.payload,
      };
    case SET_PROFILE_ID:
      return {
        ...state,
        profileId: event.payload,
      };
    case SET_POINTS:
      return {
        ...state,
        points: event.payload,
      };
    case SET_LEVEL_DATA:
      return {
        ...state,
        levelData: event.payload,
      };
    case SET_CAN_ACCESS_COMMUNITY_ADMIN_DASHBOARD:
      return {
        ...state,
        canAccessCommunityAdminDashboard: event.payload
      };
    default:
      return state;
  }
};

const ProfileProvider = ({ children }) => {
  const [state, dispatch] = useReducer(profileReducer, {});

  const value = useMemo(() => ({ state, dispatch }), [state, dispatch]);

  return (
    <ProfileContext.Provider value={value}>{children}</ProfileContext.Provider>
  );
};

const useProfile = () => {
  const context = useContext(ProfileContext);
  if (context === undefined) {
    throw new Error("useProfile must be used within a ProfileProvider");
  }

  const { state, dispatch } = context;

  const setProfile = useCallback(
    (payload) => {
      dispatch({ type: SET_PROFILE, payload });
    },
    [dispatch]
  );

  const setConnectedAddress = useCallback(
    (payload) => {
      dispatch({ type: SET_CONNECTED_ADDRESS, payload });
    },
    [dispatch]
  );

  const setOwnedNFTs = useCallback(
    (payload) => {
      dispatch({ type: SET_OWNED_NFTS, payload });
    },
    [dispatch]
  );

  const setAddresses = useCallback(
    (payload) => {
      dispatch({ type: SET_ADDRESSES, payload });
    },
    [dispatch]
  );

  const setProfileId = useCallback(
    (payload) => {
      dispatch({ type: SET_PROFILE_ID, payload });
    },
    [dispatch]
  );

  const setPoints = useCallback(
      (payload) => {
        dispatch({ type: SET_POINTS, payload });
      },
      [dispatch]
  );
  const setLevelData = useCallback(
      (payload) => {
        dispatch({ type: SET_LEVEL_DATA, payload });
      },
      [dispatch]
  );
  const setCanAccessCommunityAdminDashboard = useCallback(
      (payload) => {
        dispatch({ type: SET_CAN_ACCESS_COMMUNITY_ADMIN_DASHBOARD, payload });
      },
      [dispatch]
  );

  const clearContext = useCallback(
      () => {
        dispatch({ type: SET_PROFILE_ID, payload: '' });
        dispatch({ type: SET_ADDRESSES, payload: [] });
        dispatch({ type: SET_OWNED_NFTS, payload: [] });
        dispatch({ type: SET_CONNECTED_ADDRESS, payload: '' });
        dispatch({ type: SET_PROFILE, payload: null });
        dispatch({ type: SET_POINTS, payload: 0 });
        dispatch({ type: SET_LEVEL_DATA, payload: null });
        dispatch({ type: SET_CAN_ACCESS_COMMUNITY_ADMIN_DASHBOARD, payload: false });
      },
      [dispatch]
  );

  return {
    ...state,
    setProfile,
    setConnectedAddress,
    setOwnedNFTs,
    setAddresses,
    setProfileId,
    setPoints,
    setLevelData,
    setCanAccessCommunityAdminDashboard,
    clearContext
  };
};

export { ProfileProvider, useProfile };
