import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { Amplify } from "aws-amplify";
import awsExports from "./aws-exports";
import { SIWEProvider, ConnectKitProvider, getDefaultClient } from "connectkit";
import { chain, createClient, WagmiConfig } from "wagmi";
import siweConfig from "./utils/siweConfig";
import { ProfileProvider } from "./contexts/ProfileContext";
import "./components/Fonts/UniversNextPro-Regular.ttf";
import { Toaster } from 'react-hot-toast';
import { Network, Alchemy } from "alchemy-sdk";

Amplify.configure(awsExports);

const alchemyId = process.env.REACT_APP_ALCHEMY_API_KEY;
const infuraId = process.env.REACT_APP_INFURA_ID;
const ETH_MAINNET_CHAIN_ID = 1;
const chainId =
  parseInt(process.env.REACT_APP_CHAIN_ID) || ETH_MAINNET_CHAIN_ID;

// Choose which chains to show
const appChain = Object.values(chain).find((c) => c.id === chainId);

const client = createClient(
  getDefaultClient({
    appName: "CPG Rewards",
    alchemyId,
    infuraId,
    chains: [appChain],
  })
);

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <WagmiConfig client={client}>
      <SIWEProvider signOutOnDisconnect={true}
                    signOutOnAccountChange={false}
                    signOutOnNetworkChange={false}
                    nonceRefetchInterval={1000 * 60 * 60 * 24 * 365}
                    sessionRefetchInterval={1000 * 60 * 60 * 24 * 365}
                    {...siweConfig}>
        <ConnectKitProvider
          customTheme={{
            "--ck-font-family":
              '"Univers Next Pro Regular", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif',
            "--ck-modal-heading-font-weight": "Regular",
            "--ck-body-color": "#fff",
            "--ck-body-color-muted": "#aaa",
            "--ck-body-color-muted-hover": "#fff", 
            "--ck-body-color-danger": "#ff0000",
            "--ck-border-radius": "0px",
            "--ck-body-background": "#000",
            "--ck-primary-button-color": "#fff",
            "--ck-primary-button-background": "#000",
            "--ck-primary-button-border-radius": "0px",
            "--ck-primary-button-hover-color": "#fff",
            "--ck-primary-button-hover-background": "#0000ff",
            "--ck-primary-button-font-weight": "Regular",
            "--ck-primary-button-active-border-radius": "0px",
            "--ck-secondary-button-color": "#fff",
            "--ck-secondary-button-background": "#000",
            "--ck-secondary-button-border-radius": "0px",
            "--ck-secondary-button-hover-color": "#fff",
            "--ck-secondary-button-hover-background": "#0000ff",
            "--ck-secondary-button-font-weight": "Regular",
            "--ck-tertiary-button-color": "#fff",
            "--ck-tertiary-button-background": "#000",
            "--ck-tertiary-button-border-radius": "0px",
            "--ck-tertiary-button-hover-color": "#fff",
            "--ck-tertiary-button-hover-background": "#0000ff",
            "--ck-tertiary-button-font-weight": "Regular",
            "--ck-qr-border-radius": "0px",
            "--ck-qr-dot-color": "#fff",
            "--ck-spinner-color": "#0000ff",
            "--ck-body-background-secondary": "#fff",
          }}
        >
          <ProfileProvider>
            <Toaster
                position="top-right"
                containerStyle={{
                  top: '0.75rem',
                  right: '0.75rem'
                }}
                toastOptions={{
                    // Define default options
                    duration: 5000,
                    style: {
                        maxWidth: '22.875rem',
                        background: 'black',
                        color: 'white',
                        borderRadius: '0px'
                    },
                    // Default options for specific types
                    success: {
                        duration: 8000,
                        iconTheme: {
                            primary: '#008000',
                            secondary: 'white',
                        },
                    },
                    error: {
                        duration: 6000,
                        iconTheme: {
                            primary: '#ff0000',
                            secondary: 'white'
                        }
                    }
                }}/>
            <App />
          </ProfileProvider>
        </ConnectKitProvider>
      </SIWEProvider>
    </WagmiConfig>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
